<template>
    <div class="container">
        <section class="meeting-section">
            <div class="row flex-center">
                <div class="col-lg-6"><img class="img-responsive meeting" src="/images/assets/RPD_work_meeting.png"></div>
                <div class="col-lg-6 text-center">
                    <div class="login-inline-block">
                        <img width="80" height="80" src="/images/assets/login_page.png">
                        <h2>THE FUTURE IS HERE</h2>
                        <p class="meeting-content"><b>Scale up your business exponentially with Real Property Data as your #1 tool</b>. Increase <b>productivity</b>, discover accurate and in-depth information and strategise your next steps.</p>
                        <div class="linkedin-login" @click="linkedinlogin()"><img src="/images/assets/vector.svg"><span>Login with LinkedIn</span></div>
                        <div class="linkedin-signup" @click="linkedinlogin()"><img src="/images/assets/vector.svg"><span>Sign up with LinkedIn</span></div>
                        <div class="need-help"><b>Need assistance logging in?</b><br> Contact us below.</div>
                    </div>
                </div>
            </div>
        </section>
        <section class="contact-section" id="contact">
            <div class="contact-wrapper">
                <h2>CONTACT</h2>
                <div class="contact-subtitle">info@realpropertydata.ca</div>
                <div v-if="submitted">
                    Thank you for contacting us! We will get back to your soon.
                </div>
                <form v-else class="contact-form" v-on:submit="contactSubmit">
                    <div class="row">
                        <div class="col-lg-6">
                            <input v-model="name" placeholder="Name" name="name" class="contact-field">
                        </div>
                        <div class="col-lg-6">
                            <input v-model="email" placeholder="Email" name="email" class="contact-field">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <input v-model="title" placeholder="Inquiry Subject" name="inquiry" class="contact-field">
                        </div>
                        <div class="col-lg-6">
                            <input v-model="contact" placeholder="Contact Number" name="number" class="contact-field">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <textarea v-model="content" placeholder="Message" name="message" class="contact-long" rows="15"></textarea>
                        </div>
                    </div>
                    <button class="submit-btn" type="submit">SUBMIT</button>
                </form>
            </div>
        </section>
    </div>
</template>
<script>
    import { BACKEND_URL } from '../variables.js';
    export default {
        components: { 
        },
        directives: {
        },
        data() {
            return {
                query: this.$route.query,
                name: '',
                email: '',
                title: '',
                contact: '',
                content: '',
                submitted: false
            }
        },
        methods: {
            linkedinlogin() {
                window.location.href = '/backend/linkedin/login/0';
            },
            contactSubmit : function(e){
                e.preventDefault()
                $.post(BACKEND_URL + 'api/feedback', {
                    name: this.name,
                    email: this.email,
                    title: this.title,
                    contact: this.contact,
                    content: this.content
                }, (data, status) => {
                    if (data.status) {
                        this.submitted = true;
                    }
                });
            }
        },
        mounted() {
            document.addEventListener('scroll', function(e) {
                if (document.documentElement.scrollTop > 10) {
                    
                }
            });
        }
    }
</script>
<style scoped>
    .container {
        max-width: none;
        padding: 0;
        font-family: Poppins;
    }
    .meeting {
        max-width: 1000px;
    }
    .meeting-section {
        padding: 100px 0;
    }
    h2 {
        font-family: Poppins;
        font-weight: 700;
        font-size: 3rem;
    }
    .meeting-content {
        font-family: Poppins;
        font-weight: 300;
        font-size: 1.4rem;
        color: #000000;
        line-height: 2.3rem;
        padding: 20px;
        max-width: 550px;
    }
    .meeting-content b {
        font-weight: 500;
    }
    .need-help {
        font-family: Poppins;
        font-weight: 400;
        font-size: 1.4rem;
        max-width: 400px;
        margin: 50px auto;
    }
    .need-help.b {
        font-weight: 500;
    }
    .linkedin-login {
        background: #545454;
        color: #ffffff;
        width: 400px;
        margin: 20px auto;
        padding: 15px;
        font-size: 1.4rem;
        border-radius: 10px;
        cursor: pointer;
    }
    .linkedin-login img {
        width: 30px;
        display: inline-block;
        margin-right: 30px;
        margin-left: -30px;
        margin-top: -10px;
    }
    .linkedin-login span {
        font-weight: 400;
    }
    .linkedin-signup {
        background: #8F8A8A;
        color: #ffffff;
        width: 400px;
        margin: 20px auto;
        padding: 15px;
        font-size: 1.4rem;
        border-radius: 10px;
        cursor: pointer;
    }
    .linkedin-signup img {
        width: 30px;
        display: inline-block;
        margin-right: 30px;
        margin-left: -9px;
        margin-top: -10px;
    }
    .linkedin-signup span {
        font-weight: 400;
    }
    .contact-section {
        color: #ffffff;
        background: url('/images/assets/contactsection_bg_loginpage.jpg') 100%;
        min-height: 1000px;
    }
    .contact-subtitle {
        margin-top: 30px;
        font-size: 1.4rem;
    }
    .contact-wrapper {
        padding: 100px 0;
    }
    .contact-field {
        background: transparent;
        border: 3px solid #fff;
        border-radius: 15px;
        margin: 20px;
        font-size: 1.6rem;
        color: #ffffff;
        padding: 10px 20px;
        width: 100%;
    }
    .contact-long::placeholder {
        opacity: 1;
        color: #fff;
    }
    .contact-field::placeholder {
        opacity: 1;
        color: #fff;
    }
    .contact-long {
        background: transparent;
        border: 3px solid #fff;
        border-radius: 15px;
        font-size: 1.6rem;
        color: #ffffff;
        padding: 5px 15px;
        width: 100%;
        margin: 20px;
    }
    .contact-form {
        max-width: 1500px;
        margin: 100px auto;
    }
    .submit-btn {
        background: transparent;
        border: 3px solid #fff;
        border-radius: 30px;
        font-size: 1.4rem;
        color: #ffffff;
        padding: 10px 50px;
        width: 300px;
        margin-top: 50px;
    }
    .row {
        width: 100%;
    }
    .login-inline-block {
        display: inline-block;
    }
    @media (max-width: 991px) {
        .contact-wrapper {
            padding: 50px 0;
        }
        .contact-form {
            margin: 50px 15px;
        }
        .meeting-section {
            padding: 20px 15px;
        }
        .row {
            display: inline-block;
        }
        .contact-field {
            margin: 20px 0px;
            font-size: 1.2rem;
        }
        .contact-long {
            margin: 20px 0px;
            font-size: 1.2rem;
        }
        .login-inline-block {
            display: block;
        }
        .linkedin-login span,.linkedin-signup span {
            font-size: 1.2rem;
        }
        .linkedin-login,.linkedin-signup {
            width: 100%;
            max-width: 350px;
        }
        .linkedin-login img, .linkedin-signup img {
            margin-right: 10px;
        }
        h2 {
            font-size: 2rem;
        }
        .meeting-content {
            font-size: 1.2rem;
            padding-top: 10px;
            padding-bottom: 10px;
            line-height: 32px;
        }
    }
</style>