import Options from './components/Options.vue';
import Listing from './components/Listing.vue';
import Home from './components/Home.vue';
import MLSList from './components/MLSList.vue';
import Report from './components/Report.vue';
import planningReport from './components/planningReport.vue';
import Projects from './components/NewProjects.vue';
// import Projects from './components/Projects.vue';
// import Type from './components/Type.vue';
import UTC from './components/UTC.vue';
import PasswordReset from './components/PasswordReset.vue';
import OnetimeLogin from './components/OnetimeLogin.vue';
import Contact from './components/Contact.vue';
import MLSEntry from './components/MLSEntry.vue';
import MLSEntryEdit from './components/MLSEntryEdit.vue';
import LandList from './components/LandList.vue';
import LandListNew from './components/LandListNew.vue';
import ListingNew from './components/ListingNew.vue';
import Plans from './components/Plans.vue';
import Login from './components/Login.vue';
import Permits from './components/Permits.vue';

export const routes = [
    { path: '', component: Home},
    { path: '/presale-database', component: Login},
    { path: '/options', component: Options},
    { path: '/report', component: Report},
    { path: '/utc-report-planning', component: planningReport},
    { path: '/listing', component: Listing},
    { path: '/mls-list', component: MLSList},
    { path: '/land-list', component: LandList},
    { path: '/land-list-new', component: LandListNew},
    { path: '/project', component: Projects},
    { path: '/type', component: UTC },
    { path: '/reset-password', component: PasswordReset },
    { path: '/onetime-login', component: OnetimeLogin },
    { path: '/contact', component: Contact },
    { path: '/mls_entry_update', component: MLSEntry },
    { path: '/mls_entry_edit', component: MLSEntryEdit },
    { path: '/plans', component: Plans },
    { path: '/planning-updates', component: Permits },
    { path: '/listing-new', component: ListingNew },
]