<template>
    <div v-cloak>
        <div class="report-container column-report">
            <img v-if="is_loading" style="width: 100%;" src="/images/spinner.gif">
            <div class="status-row" v-if="!is_loading">
            </div>
            <table v-if="!is_loading" class="table list-table">
                <thead class="summary-table-header">
                    <tr class="summary-table-header-row navy-header">
                        <th @click="project_sort('sort-uname')">UTC Project Name</th>
                        <th @click="project_sort('sort-unit')">Unit #</th>
                        <th @click="project_sort('sort-pname')">Plan Name</th>
                        <th @click="project_sort('sort-price')">List Price</th>
                        <th @click="project_sort('sort-beds')">Beds</th>
                        <th @click="project_sort('sort-baths')">Baths</th>
                        <th @click="project_sort('sort-usize')">Unit Size</th>
                        <th @click="project_sort('sort-psf')">$PSF</th>
                        <th @click="project_sort('sort-status')">Status</th>
                        <th @click="project_sort('sort-view')">View</th>
                    </tr>
                </thead>
                <tbody>
                    <tr style="cursor: pointer;" v-for="(row, key) in mlsSort" v-bind:key="key" @click="popHistory(row.entity_id)" v-b-modal="'mlspop'">
                        <td>
                            <a :href="utcLink(row)">{{row.field_utc_project_name_value}}</a>
                        </td>
                        <td>
                            {{row.field_mls_address_value}}
                        </td>
                        <td>
                            {{row.field_mls_plan_name_value}}
                        </td>
                        <td>
                            ${{row.field_mls_list_price_value | numFormat}}
                        </td>
                        <td>
                            {{row.field_mls_beds_value}}
                        </td>
                        <td>
                            {{row.field_mls_baths_value}}
                        </td>
                        <td>
                            {{row.field_mls_unit_size_value}}
                        </td>
                        <td>
                            {{row.field_mls_psf_value}}
                        </td>
                        <td>
                            {{row.status_name_name}}
                        </td>
                        <td>
                            {{row.name}}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <b-modal id="mlspop" hide-footer>
            <table class="table mt-3">
                <thead class="fp-header">
                    <th>List Price</th>
                    <th>Beds</th>
                    <th>Baths</th>
                    <th>$PSF</th>
                    <!-- <th>DOM</th> -->
                    <th>Sold Price</th>
                    <th>SP Sqft</th>
                    <th>Sold Date</th>
                    <th>Status</th>
                    <th>Collection Type</th>
                </thead>
                <tbody class="fp-body">
                    <tr v-for="(row, index) in mlsHistory" style="cursor: pointer;" :key="index">
                        <td>${{row.mls_list_price | numFormat}}</td>
                        <td>{{row.bed}}</td>
                        <td>{{row.baths}}</td>
                        <td v-if="row.mls_psf > 0">${{row.mls_psf | numFormat}}</td>
                        <td v-else>-</td>
                        <!-- <td>{{row.mls_dom}}</td> -->
                        <td v-if="row.mls_sold_price != '-'">${{row.mls_sold_price | numFormat}}</td><td v-else>-</td>
                        <td v-if="row.mls_sp_sqft > 0">${{row.mls_sp_sqft | numFormat}}</td><td v-else>-</td>
                        <td>{{row.mls_sold_date}}</td>
                        <td>{{row.mls_status}}</td>
                        <td>{{row.collection_type}}</td>
                    </tr>
                </tbody>
            </table>
        </b-modal>
    </div>
</template>
<script>
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    import {BACKEND_URL} from '../variables.js';
    import {BTabs, BTab, BPopover, BPopoverDirective, BModal, BModalDirective, VBModal} from 'bootstrap-vue';
    import Treeselect from '@riophae/vue-treeselect'
    import '@riophae/vue-treeselect/dist/vue-treeselect.css'
    import { cloneDeep } from 'lodash';

    export default {
        components: { 
            DatePicker,
            'b-tabs': BTabs,
            'b-tab': BTab,
            'b-popover': BPopover,
            'b-modal': BModal,
            Treeselect,
        },
        directives: {
            'b-popover': BPopoverDirective,
            'b-modal': VBModal,
        },
        data() {
            return {
                is_loading: true,
                query: this.$route.query,
                mlsData: [],
                sortBy: 'sort-uname',
                orderAsc: true,
                mlsHistory: []
            }
        },
        methods: {
            project_sort(column) {
                if (this.sortBy == column) {
                    this.orderAsc = !this.orderAsc;
                }
                this.sortBy = column;
            },
            utcLink(utc) {
                let link = '/type?project=' + utc.city_entity_id + '&fid=' + utc.field_project_type_collection_value + '&sid=' + utc.field_utc_status_tid + '&uid=' + utc.field_unit_type_value;
                return link;
            },
            getMLSDetail (params) {
                const endPoint = `api/get_mls_list?${params}`;
                this.is_loading = true;
                $.get({
                    url: BACKEND_URL + endPoint,
                }, (data, status) => {
                    this.is_loading = false;
                    if (status !== 'success') return;
                    this.mlsData = cloneDeep(data);
                });
            },
            popHistory(entity_id) {
                let entry = 'eid=' + entity_id;
                const endPoint = `api/get_mls_history?${entry}`;
                $.get({
                    url: BACKEND_URL + endPoint,
                }, (data, status) => {
                    if (status !== 'success') return;
                    this.mlsHistory = data;
                });
            }
        },
        computed: {
            mlsSort: function () {
                let alphSorting = {
                    'sort-uname' : 'field_utc_project_name_value',
                    'sort-unit' : 'field_mls_address_value',
                    'sort-pname' : 'field_mls_plan_name_value',
                    'sort-price' : 'field_mls_list_price_value',
                    'sort-beds' : 'field_mls_beds_value',
                    'sort-baths' : 'field_mls_baths_value',
                    'sort-usize' : 'field_mls_unit_size_value',
                    'sort-psf' : 'field_mls_psf_value',
                    'sort-status' : 'status_name_name',
                    'sort-view' : 'name',
                }
                var vm = this;
                var ob = this.mlsData;
                Object.keys(alphSorting).forEach(function (k) {
                    if (k == vm.sortBy) {
                        if (vm.orderAsc) {
                            ob = vm.mlsData.sort((a,b) => (a[alphSorting[k]] > b[alphSorting[k]]) ? 1 : ((b[alphSorting[k]] > a[alphSorting[k]]) ? -1 : 0));
                        }
                        else {
                            ob = vm.mlsData.sort((a,b) => (a[alphSorting[k]] < b[alphSorting[k]]) ? 1 : ((b[alphSorting[k]] < a[alphSorting[k]]) ? -1 : 0));
                        }
                    }
                });
                return ob;
            }
        },
        mounted: function() {
            let param = ''
            var vm = this;
            let rangList = [
                'mlsbedsmax',
                'mlsbedsmin',
                'mlsbathsmax',
                'mlsbathsmin',
                'mlslistpricemax',
                'mlslistpricemin',
                'mlssoldpricemax',
                'mlssoldpricemin',
                'mlsunitmax',
                'mlsunitmin'
            ];

            rangList.forEach((d) => {
                if (vm.query[d]) {
                    param += d+'=' + vm.query[d] + '&';
                }
            });
            let dateList = [
                'listmlsdate',
                'soldmlsdate'
            ];
            dateList.forEach((d) => {
                if ($.isArray(vm.query[d+'[]'])) {
                    vm.query[d+'[]'].forEach((a) => {
                        param += d+'[]=' + a + '&';
                    });
                }
                else if (vm.query[d+'[]']) {
                    param += d+'[]=' + vm.query[d+'[]'] + '&';
                }
            });

            if ($.isArray(vm.query['gut[]'])) {
                vm.query['gut[]'].forEach((a) => {
                    param += 'gut[]=' + a + '&';
                });
            }
            else {
                if (vm.query['gut[]']) {
                    param += 'gut[]=' + vm.query['gut[]'] + '&';
                }
            }

            if ($.isArray(vm.query['fp[]'])) {
                vm.query['fp[]'].forEach((a) => {
                    param += 'fp[]=' + a + '&';
                });
            }
            else {
                if (vm.query['fp[]']) {
                    param += 'fp[]=' + vm.query['fp[]'] + '&';
                }
            }

            if ($.isArray(vm.query['city[]'])) {
                vm.query['city[]'].forEach((a) => {
                    param += 'city[]=' + a + '&';
                });
            }
            else {
                if (vm.query['city[]']) {
                    param += 'city[]=' + vm.query['city[]'] + '&';
                }
            }

            if ($.isArray(vm.query['type[]'])) {
                vm.query['type[]'].forEach((a) => {
                    param += 'type[]=' + a + '&';
                });
            }
            else {
                if (vm.query['type[]']) {
                    param += 'type[]=' + vm.query['type[]'] + '&';
                }
            }

            if ($.isArray(vm.query['status[]'])) {
                vm.query['status[]'].forEach((a) => {
                    param += 'status[]=' + a + '&';
                });
            }
            else {
                if (vm.query['status[]']) {
                    param += 'status[]=' + vm.query['status[]'] + '&';
                }
            }

            this.getMLSDetail(param)
        }
    }
</script>
<style scoped>
th>div {
    position: relative;
}
th>div>span {
    white-space: nowrap;
}
th>div>div {
    position: absolute;
}
fa {
    font-size: 18px;
}
th ul {
    border: 1px solid #eee;
    border-radius: 4px;
    position: absolute;
    left: 50%;
    top: 24px;
    transform: translateX(-50%);
    overflow: hidden;
}
.visible {
    max-height: 100px;
    transition: max-height .3s;
}
.invisible {
    max-height: 0px;
    transition: max-height .3s;
}
.rotate-180 {
    transform: rotate(180deg);
    transition: transform .3s;
}
.rotate-360 {
    transform: rotate(360deg);
    transition: transform .3s;
}
th ul li {
    background: #fff;
    padding: 8px 16px;
    border-bottom: 1px solid #dee2e6;
    color: #212529;
    text-transform: none;
    font-size: 12px;
    white-space: nowrap;
    height: 32px;
    text-align: left;
    cursor: pointer;
}
th ul li:hover {
    background: #4db7fe;
    color: #fff;
}
th ul li:last-child {
    border-bottom: none;
}
.selected {
    background: #4db7fe;
    color: #fff;
}
a {
    color: #007bff;
}
.report-container {
    padding: 30px 50px;
}
.status-btn {
    background: #EEF4FB;
    border-radius: 25px;
    font-size: 20px;
    line-height: 18px;
    color: #415A8F;
    padding: 10px 30px;
    margin-right: 30px;
    display: inline-block;
}
.status-row {
    margin-top: 20px; 
    margin-bottom: 50px;
    text-align: left;
}
.status-line {
    line-height: 5;
}
.summary-table-header-row {
    cursor: pointer;
}
</style>