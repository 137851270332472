<template>
    <div class="container">
        <div class="fl-wrap single-project-detail">
            <div class="row pb-4">
                <div class="col-md-8">
                    <h1 class="type-header-i">Land</h1>
                    <span class="pl-3">
                        <a v-if="project.nid" :href="'https://www.realpropertydata.ca/backend/node/'+project.nid+'/edit'">Edit</a>
                    </span>
                </div>
            </div>
            <div class="row">
                <div class="col-md-9 text-left">
                    <div class="row">
                        <span class="type-ad-header mr-5 ml-3">{{project.project_title}}</span>
                        <span class="type-ad-header mr-5 ml-3">Surrey</span>
                        <span class="type-ad-header mr-5 ml-5">{{project.developer}}</span>
                    </div>
                </div>
            </div>
            <hr />
            <b-tabs active-nav-item-class="type-nav-active" active-tab-class="type-content-active" nav-class="type-nav-general">
                <b-tab title="Application Overview">
                    <div class="container">
                        <div class="row py-3">
                            <div class="col-md-12">
                                <table class="table">
                                    <thead class="fp-header">
                                        <th>PID</th>
                                        <th>Address</th>
                                        <th>Status</th>
                                        <th>Registration Type</th>
                                        <th>Sales Price</th>
                                        <th>Registered Owner</th>
                                        <th>Lot Size</th>
                                        <th>Date</th>
                                    </thead>
                                    <tbody class="fp-body">
                                        <tr v-for="(value, key) in landInfo" :key="key">
                                            <td>{{value.pid}}</td>
                                            <td>{{value.address}}</td>
                                            <td>{{value.status}}</td>
                                            <td>
                                                <template v-if="selectedDateMap[key].selectedDate">
                                                    {{value.reg[selectedDateMap[key].selectedDate].registration_type}}
                                                </template>
                                            </td>
                                            <td>
                                                <template v-if="selectedDateMap[key].selectedDate">
                                                    {{value.reg[selectedDateMap[key].selectedDate].sale_price}}
                                                </template>
                                            </td>
                                            <td>
                                                <template v-if="selectedDateMap[key].selectedDate">
                                                    {{value.reg[selectedDateMap[key].selectedDate].purchaser}}
                                                </template>
                                            </td>
                                            <td>
                                                <template v-if="selectedDateMap[key].selectedDate">
                                                    {{value.reg[selectedDateMap[key].selectedDate].size}}
                                                </template>
                                            </td>
                                            <td v-if="selectedDateMap[key]">
                                                <b-form-select v-model="selectedDateMap[key].selectedDate" @change="selectedDate($event, key)">
                                                    <b-form-select-option v-for="(reg, regKey) in value.reg" :key="regKey+key" :value="regKey">
                                                        {{regKey}}
                                                    </b-form-select-option>
                                                </b-form-select>
                                            </td>
                                            <td v-else>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div style="background: #79B8F2; width: 100%; display: inline-block; height: 20px;" v-b-modal="'city-app-presale'"></div>
                                <b-modal id="city-app-presale" hide-footer :title="'City Application Doc'">
                                    <div class="col-md-4 d-block text-center" style="margin-bottom: 20px;" v-for="(file, key) in project.city_app_data" :key="key">
                                        <a target="_blank" :href="file.planning_doc_file.uri" style="font-size: 17px;">{{file.planning_doc_file.filename}}</a>
                                    </div>
                                </b-modal>
                                <div class="row pt-4 text-left">
                                    <div class="px-2 col-md-12">
                                        <div class="row p-2">
                                            <div class="col-md-5 type-list-label">
                                                Total Lot Area
                                            </div>
                                            <div class="col-md-7 type-list-content">
                                                {{project.lot}} sqft
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div class="row pt-4 text-left">
                                    <div class="col-md-5">
                                        Planning Designation
                                    </div>
                                    <template v-if="project.planning_desig">
                                        <span class="col-md-7" v-b-modal="'planning_desig'"
                                            style="cursor: pointer;"
                                            v-if="project.planning_desig.file.length > 0">
                                            <div>{{project.planning_desig.name}}</div>
                                        </span>
                                        <div v-else class="col-md-7">{{project.planning_desig.name}}</div>
                                        <b-modal id="planning_desig" hide-footer :title="'Planning Designation'">
                                            <div class="col-md-4 d-block text-center" style="margin-bottom: 20px;" v-for="(file, key) in project.planning_desig.file" :key="key">
                                                <a target="_blank" :href="file.file" style="font-size: 17px;">{{file.title}}</a>
                                            </div>
                                        </b-modal>
                                    </template>    
                                </div>
                                <hr />  
                                <div v-for="(item, key) in  presaleInfoList">
                                    <div class="row pt-4 text-left" :key="key">
                                        <div class="col-md-5">
                                            {{item.title}}
                                        </div>
                                        <ul v-if="project[item.field]" class="col-md-7">
                                            <li v-for="(single, skey) in project[item.field]" :key="skey+key">
                                                <a class="col-md-7" target="_blank" 
                                                    :href="single.file" 
                                                    v-if="single.file">
                                                    <div>{{single.name}}</div>
                                                </a>
                                                <div v-else class="col-md-7">{{single.name}}</div>
                                            </li>
                                        </ul>
                                    </div>
                                    <hr :key="key" />
                                </div>
                                <!-- <div class="row pt-4">
                                    <div class="col-md-12">
                                        <table class="table w-100 col-md-12">
                                            <thead class="fp-header">
                                                <th>CITY APPLICATION #</th>
                                                <th>PLANNING DOCUMENTS FILE</th>
                                            </thead>
                                            <tbody class="fp-body">
                                                <tr v-for="(item, key) in project.city_app_data" :key="key">
                                                    <td><a :href="item.planning_doc" target="_blank">{{item.title}}</a></td>
                                                    <td><a :href="item.planning_doc_file.uri"  target="_blank">{{item.planning_doc_file.filename}}</a></td>
                                                </tr>
                                                <tr v-for="(item, key) in project.bylaw_data" :key="key">
                                                    <td><a :href="item.planning_doc" target="_blank">{{item.title}}</a></td>
                                                    <td><a :href="item.planning_doc_file.uri"  target="_blank">{{item.planning_doc_file.filename}}</a></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div> -->
                            </div>
                            <div class="col-md-6 pt-1">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div id='map' style="height: 300px"></div>
                                    </div>
                                </div>
                                <!-- <div class="row py-3">
                                    <div class="col-md-12">
                                        <table class="table">
                                            <thead class="fp-header">
                                                <th>Type</th>
                                                <th>Ref</th>
                                            </thead>
                                            <tbody class="fp-body">
                                                <tr v-for="(value, key) in project.consultants" :key="key">
                                                    <td>{{key}}</td>
                                                    <td>{{value[0]}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                        <div class="row pt-4">
                            <div class="col-md-12">
                                <table class="table">
                                    <thead class="fp-header">
                                        <th></th>
                                        <th>Units</th>
                                        <th>Floor Area</th>
                                        <th>Floor Area (gross)</th>
                                        <th>FSR/FAR</th>
                                        <th>Parking</th>
                                        <th>Studio</th>
                                        <th>1-bed</th>
                                        <th>2-bed</th>
                                        <th>3-bed</th>
                                        <th>4-bed</th>
                                    </thead>
                                    <tbody class="fp-body">
                                        <tr v-for="(value, key) in project.total_table" :key="key">
                                            <td>{{key}}</td>
                                            <td>{{typeof value.total_units != 'undefined' && value.total_units.value?value.total_units.value:'-'}}</td>
                                            <td>{{typeof value.total_floor_area != 'undefined' && value.total_floor_area.value | numFormat}}</td>
                                            <td>{{typeof value.gross != 'undefined' && value.gross.value | numFormat}}</td>
                                            <td>{{typeof value.total_fsr != 'undefined' && value.total_fsr.value?value.total_fsr.value: '-'}}</td>
                                            <td>{{typeof value.total_parking != 'undefined' && value.total_parking.value?value.total_parking.value:'-'}}</td>
                                            <td>{{typeof value.total_studio != 'undefined' && value.total_studio.value?value.total_studio.value:'-'}}</td>
                                            <td>{{typeof value.total_1_bed != 'undefined' && value.total_1_bed.value?value.total_1_bed.value:'-'}}</td>
                                            <td>{{typeof value.total_2_bed != 'undefined' && value.total_2_bed.value?value.total_2_bed.value:'-'}}</td>
                                            <td>{{typeof value.total_3_bed != 'undefined' && value.total_3_bed.value?value.total_3_bed.value:'-'}}</td>
                                            <td>{{typeof value.total_4_bed != 'undefined' && value.total_4_bed.value?value.total_4_bed.value:'-'}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </b-tab>
                <b-tab v-for="(value, key) in project.type_group" :key="key" :active="checkTab(value.ptc.fid)">
                    <template #title>
                        <div style="font-size: 14px; line-height: 17px;">{{value.ptc.name}}</div>
                        <div style="font-size: 14px; line-height: 17px;">{{value.ptc.type}}</div>
                        <div style="font-size: 14px; line-height: 17px;" v-if="value.ptc.lot">Lot {{value.ptc.lot}}</div>
                    </template>
                        <ProjectTypeGroup :groupInfo="value" :location="value.ptc.geo_data"></ProjectTypeGroup>
                </b-tab>
            </b-tabs>
        </div>
    </div>
</template>
<script>
import { BTabs, BTab } from 'bootstrap-vue'
import { BACKEND_URL } from '../variables.js'
import { initGoogleMapMulti } from '../shared/services'
import { ProjectTypeGroup } from '../shared/components'
import {BModal, VBModal} from 'bootstrap-vue'

export default {
    components: {
        'b-tabs': BTabs,
        'b-tab': BTab,
        'b-modal': BModal,
        'ProjectTypeGroup': ProjectTypeGroup
    },
    directives: {
        'b-modal': VBModal,
    },
    data() {
        return {
            project: {},
            projectId: this.$route.query.project,
            landId: this.$route.query.land,
            presaleInfoList: [
                // {  title: 'Planning Designation', field: 'planning_desig'},
                {  title: 'Existing Zoning', field: 'ez_zon'},
                {  title: 'Proposed Zoning', field: 'proposed_zon'}
            ],
            landInfo: {},
            selectedDateMap: {},
            activeTab: null,
            query: this.$route.query
        }
    },
    mounted() {
        this.getProjectDetail()
    },
    methods: {
        checkTab: function(fid, key) {
            if (fid == this.landId) {
                return true
            }
            else if (this.landId == 'presale') {
                if (fid == Object.keys(this.project.type_group)[0]) {
                    return true;
                }
            }
            else if (fid == this.$route.query.tab) {
                return true
            }
        },
        getProjectDetail: function() {
            $.get({
                url: `${BACKEND_URL}api/get_single_project?pid=${this.projectId}`,
            }, (data, status) => {
                if (!data) return
                this.project = data
                this.landInfo = data.land_info
                Object.keys(this.landInfo).forEach((key) => {
                    this.selectedDateMap[key] = { selectedDate: Object.keys(this.landInfo[key].reg)[0] }
                })
                setTimeout(() => {
                    initGoogleMapMulti(
                        this.project.type_group,
                        document.getElementById('map'),
                    )
                })
            });

        },
        selectedDate: function(value, propertyName) {
            this.selectedDateMap = Object.assign({}, this.selectedDateMap, {[propertyName]: {selectedDate: value}})
        }
    }
}
</script>
<style scoped>
.plr-20 {
    margin-left: -20px;
    margin-right: -20px;
}
tr:last-child {
    border-bottom: 1px solid #dee2e6;
}
tbody.fp-body td{
    vertical-align: middle;
}
select {
    width: 216px;
}
</style>