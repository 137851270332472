import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import {routes} from './routes'
import jQuery from "jquery";
import Slick from 'vue-slick';
import BootstrapVue from 'bootstrap-vue';
import VueLodash from 'vue-lodash';
import numeral from 'numeral';
import numFormat from 'vue-filter-number-format';
// import VueAnalytics from 'vue-analytics';
import VueGtag from "vue-gtag";


require('./assets/styles/font.css')
require('./assets/styles/reset.css')
require('./assets/styles/color.css')
// require('./assets/styles/date-droop-color.css')
require('./assets/styles/plugins.css')
require('./assets/styles/style.css')
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueCookies from 'vue-cookies'
import { BDropdown, BDropdownItem, BDropdownDivider, BDropdownText, BFormSelect, BFormSelectOption, BCollapse } from 'bootstrap-vue';

Vue.component('b-dropdown', BDropdown);
Vue.component('b-dropdown-item', BDropdownItem);
Vue.component('b-dropdown-divider', BDropdownDivider);
Vue.component('b-dropdown-text', BDropdownText);
Vue.component('b-form-select', BFormSelect)
Vue.component('b-form-select-option', BFormSelectOption)
Vue.component('b-collapse', BCollapse)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

Vue.use(VueRouter, BootstrapVue, VueLodash)
Vue.use(VueCookies);

Vue.$cookies.config('7d')
Vue.filter('numFormat', numFormat(numeral));

const router = new VueRouter({
    routes,
    mode: 'history'
})

Vue.use(VueGtag, {
  config: { id: "G-NDVH0056TS" }
});

new Vue({
    el: '#app',
    router,
    render: h => h(App),
    components: { Slick, jQuery},
})
