<template>
    <div class="body" :class="{ 'type-page': $route.path === '/type' }">
        <!-- header-->
        <header v-if="$route.path !== '/type'" class="main-header fs-header" :class="{'front': $route.path == '/', 'loggedin' : role}">
            <div class="header-inner row">
                <div class="hidden-lg hidden-md"><div class="fa fa-bars mobile-nav" v-click-outside="menuOff" @click="menuOn = !menuOn"></div></div>
                <div class="logo">
                    <a class="logo-link" href="/"><img class="logo-img" src="/images/assets/logo/RPD_logotype_skyblue.png"></a>
                </div>
                <div class="header-col split hidden-xs hidden-sm"><a href="/#feature">FEATURES</a></div>
                <div class="header-col hidden-xs hidden-sm" v-if="!userLoggedIn"><a href="/#demo">DEMO</a></div>
                <div class="header-col hidden-xs hidden-sm"><a href="/#package">PACKAGES</a></div>
                <div class="header-col hidden-xs hidden-sm" v-if="!userLoggedIn"><a href="/presale-database">LOGIN</a></div>
                <template v-if="userLoggedIn"><div class="header-col hidden-xs hidden-sm" @click="logout()">LOG OUT</div></template>
                <div class="header-col hidden-xs hidden-sm"><a href="/presale-database#contact">CONTACT</a></div>
            </div>
            <div class="mobile-nav-wrapper" :class="{'on': menuOn}">
                <ul>
                    <li><a href="/#feature">FEATURES</a></li>
                    <li v-if="!userLoggedIn"><a href="/#demo">DEMO</a></li>
                    <li><a href="/#package">PACKAGES</a></li>
                    <li v-if="!userLoggedIn"><a href="/presale-database">LOGIN</a></li>
                    <template v-if="userLoggedIn"><li><div class="" @click="logout()">LOG OUT</div></li></template>
                    <li><a href="/presale-database#contact">CONTACT</a></li>
                </ul>
                <div class="social-icons-mobile">
                    <img class="footer-social-media" src="/images/assets/instagram_icon.png">
                    <img class="footer-social-media" src="/images/assets/linkedin_icon.png">
                </div>
            </div>
        </header>
        <!--  header end -->
        <div id="wrapper" :class="{'front-wrap': $route.path == '/'}">
            <!-- Content-->
            <div class="content full-height">
                <router-view></router-view>
            </div>
        </div>
        <!--register form -->
        <!-- <div class="feedbackSwitch" @click="openFeedback()">Feedback</div>
        <form class="contact-us contact-closed" v-on:submit.prevent>
            <div class="contact-close" @click="closeFeedback()">X</div>
            <template v-if="!feedbackFinished">
                <div class="form-group">
                    <h1 style="font-size: 18px; margin-bottom: 20px; font-weight: bold;" class="text-left">Feedback</h1>
                    <textarea v-model="feedbackMessage" class="form-control" id="contact-message" rows="5"></textarea>
                    <small id="messageHelp" class="form-text text-muted">Please let us know if you have any comments</small>
                </div>
                <button @click="feedbackSubmit()" type="submit" class="btn btn-primary">Submit</button>
            </template>
            <template v-else>
                <div style="font-size: 16px;padding: 100px 0;">Thank you for your feedback!</div>
            </template>
        </form> -->
        <div v-if="!userLoggedIn" class="main-register-wrap modal">
            <div class="main-overlay"></div>
            <div class="main-register-holder">
                <div class="main-register fl-wrap">
                    <div class="close-reg"><i class="fa fa-times"></i></div>
                    <h3>Sign In <span><strong>Realpropertydata</strong></span></h3>
                    <!-- <div class="soc-log fl-wrap">
                        <p>For faster login or register use your social account.</p>
                        <GoogleLogin :params="params" :renderParams="renderParams" :onSuccess="onSuccess" :onFailure="onFailure"></GoogleLogin>
                    </div> -->
                    <!-- <div class="log-separator fl-wrap"><span>or</span></div> -->
                    <div id="tabs-container">
                        <!-- <ul class="tabs-menu">
                            <li class="current"><a href="#tab-1">Login</a></li>
                            <li><a href="#tab-2">Register</a></li>
                        </ul> -->
                        <div class="tab">
                            <div id="tab-1" class="tab-content">
                                <div class="custom-form">
                                    <form method="post"  name="registerform">
                                        <!-- <div class="alert alert-danger text-left" v-if="message">{{message}}</div>
                                        <label>Username *</label>
                                        <input name="username" type="text" onClick="this.select()" value="" v-model="loginUsername"> 
                                        <label >Password * </label>
                                        <input name="password" type="password" onClick="this.select()" value="" v-model="loginPassword"> 
                                        <div type="submit"  class="log-submit-btn" @click="login()"><span>Log In</span></div> 
                                        <div type="submit" style="clear: left;"  class="log-submit-btn" @click="linkedinlogin()"><span>LinkedIn Login</span></div> 
                                        <div class="clearfix"></div> -->
                                        <div type="submit" style="clear: left;"  class="log-submit-btn" @click="linkedinlogin()"><span>LinkedIn Login</span></div> 
                                    </form>
                                    <!-- <div class="lost_password">
                                        <a href="#">Lost Your Password?</a>
                                    </div> -->
                                </div>
                            </div>
                            <!-- <div class="tab">
                                <div id="tab-2" class="tab-content">
                                    <div class="custom-form">
                                        <form method="post"   name="registerform" class="main-register-form" id="main-register-form2">
                                            <label >First Name * </label>
                                            <input name="name" type="text"   onClick="this.select()" value=""> 
                                            <label>Second Name *</label>
                                            <input name="name2" type="text"  onClick="this.select()" value="">
                                            <label>Email Address *</label>
                                            <input name="email" type="text"  onClick="this.select()" value="">                                              
                                            <label >Password *</label>
                                            <input name="password" type="password"   onClick="this.select()" value="" > 
                                            <button type="submit"     class="log-submit-btn"  ><span>Register</span></button> 
                                        </form>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--register form end -->
        <!--footer -->
        <footer v-if="$route.path == '/'">
            <div class="footer-wrapper" :class="{'front': $route.path == '/', 'loggedin' : role}">
                <div class="row footer-blocks">
                    <div class="col-lg-6 col-xs-12 footer-inner" v-if="!role">
                        <div class="footer-sub-block">
                            We do the dirty work so our clients can stay focused on their business.
                        </div>
                        <input class="presale-input-field footer-sub" placeholder="Enter your Email" v-if="!role">
                        <img class="sub-btn" src="/images/assets/vector2.svg">
                    </div>
                    <div v-else class="col-lg-6 col-xs-12 footer-inner">
                        <div class="footer-sub-block">
                            Accelerate your business goals with us. Turn data to value today.
                        </div>
                        <div class="footer-login-sub-block">#RealPropertyData</div>
                    </div>
                    <div class="col-lg-4 col-xs-6">
                        <a href="/presale-database#contact" class="footer-link">CONTACT</a>
                        <a href="/#feature" class="footer-link">FEATURES</a>
                        <a href="/#package" class="footer-link">PACKAGES</a>
                        <div v-if="!userLoggedIn" class="footer-link">SIGNUP</div>
                        <a v-if="!userLoggedIn" href="/#demo" class="footer-link">DEMO</a>
                    </div>
                    <div class="col-lg-2 col-xs-6">
                        <div class="social-logo">
                            <a href="/"><img class="footer-logo" src="/images/assets/logo/RPD_logomark_type_white.png"></a>
                        </div>
                        <div class="social-icons">
                            <img class="footer-social-media" src="/images/assets/instagram_icon.png">
                            <img class="footer-social-media" src="/images/assets/linkedin_icon.png">
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        <!--footer end  -->
    </div>
</template>
<script>
    import GoogleLogin from 'vue-google-login';
    import {BACKEND_URL} from './variables.js';
    import { page } from 'vue-analytics';
    import ClickOutside from 'vue-click-outside';

    export default {
        name: 'App',
        data() {
            return {
                // client_id is the only required property but you can add several more params, full list down bellow on the Auth api section
                params: {
                    client_id: "157056203732-n7gslke60l1jivb6qu1dndhg60d19bj0.apps.googleusercontent.com"
                },
                // only needed if you want to render the button with the google ui
                renderParams: {
                    width: 250,
                    height: 50,
                    longtitle: true
                },
                userLoggedIn: false,
                usermail: '',
                loginUsername: '',
                loginPassword: '',
                query: this.$route.query,
                message: '',
                feedbackMessage: '',
                feedbackFinished: false,
                role: '',
                active: false,
                menuOn: false
            }
        },
        components: {
            GoogleLogin
        },
        directives: {
            ClickOutside
        },
        created () {
            window.addEventListener('scroll', this.handleScroll);
        },
        destroyed () {
            window.removeEventListener('scroll', this.handleScroll);
        },
        methods: {
            menuOff() {
                this.menuOn = false;
            },
            feedbackSubmit() {
                $.post(BACKEND_URL + 'api/feedback', {
                    feedbackMessage: this.feedbackMessage,
                    path: this.$router.currentRoute.fullPath,
                }, (data, status) => {
                    this.feedbackFinished = true;
                });
            },
            openFeedback() {
                $('.contact-us').removeClass('contact-closed');
                $('.feedbackSwitch').addClass('switch-closed');
            },
            closeFeedback() {
                $('.contact-us').addClass('contact-closed');
                $('.feedbackSwitch').removeClass('switch-closed');
            },
            track () {
                page('/')
            },
            linkedinlogin() {
                window.location.href = '/backend/linkedin/login/0';
            },
            login() {
                this.message = '';
                $.post(BACKEND_URL + 'api/custom_login', {
                    name: this.loginUsername,
                    password: this.loginPassword
                }, (data, status) => {
                    if (data.status) {
                        this.userLoggedIn = true;
                        this.usermail = data.email;
                        $('.main-register-wrap.modal').hide();
                        location.reload();
                    }
                    else {
                        this.message = data.message;
                    }
                });
            },
            onSuccess(googleUser) {
                var id_token = googleUser.getAuthResponse().id_token;
                if (id_token) {
                    $.post(BACKEND_URL + 'api/google_login', {
                        id_token: id_token
                    }, (data, status) => {
                        if (data.uid) {
                            this.userLoggedIn = true;
                            this.usermail = data.mail;
                            $('.main-register-wrap.modal').hide();
                        }
                    });
                }
            },
            onFailure() {

            },
            checkLogin() {
                $.get(BACKEND_URL + 'api/current_user', (data, status) => {
                    if (data.uid) {
                        this.userLoggedIn = true;
                        this.usermail = data.mail;
                        this.role = data.role
                    }
                });
            },
            logout() {
                $.get(BACKEND_URL + 'api/api_logout_user', (data, status) => {
                    this.$router.push({ path: '/'});
                    this.$router.go();
                });
            },
            handleScroll(event) {
                if (document.documentElement.scrollTop > 10) {
                    this.active = true
                }
                else {
                    this.active = false
                }
            }
        },
        mounted: function() {
            this.checkLogin();
        }
    }
</script>
<style scoped>
    .footer-inner {
        text-align: left;
    }
    .front.loggedin {
        display: none;
    }
    .body {
        background: #13293E;
    }
    .body {
        height: calc(100% - 150px);
    }
    .body.type-page {
        height: 100%;
    }
    .header-col {
        color: #fff;
        font-family: Poppins;
        font-size: 1.4rem;
        font-weight: 500;
        margin-right: 40px;
    }
    .header-col a {
        color: #ffffff;
    }
    .split {
        margin-left: auto;
    }
    .header-inner {
        color: #ffffff;
        background: transparent;
        align-items: center;
        height: 100%;
        cursor: pointer;
    }
    .logo-img {
        max-width: 400px;
    }
    h1 {
        font-family: Poppins;
        font-weight: 700;
        font-size: 2.6rem;
    }
    h2 {
        font-size: 1.6rem;
    }
    .main-header {
        position: relative;
        background: #13293E;
        height: 150px;
        box-shadow: none;
        transition: background 0.3s;
        z-index: 10;
    }
    .main-header.front {
        position: absolute;
        top: 0;
        background: transparent;
        width: 100%;
    }
    .main-header.active {
        background: #13293E;
    }
    .footer-wrapper {
        background: #13293E;
        color: #ffffff;
        display: inline-block;
        width: 100%;
        padding: 100px 0;
    }
    .footer-blocks {
        margin: auto;
        max-width: 1400px;
    }
    .footer-sub-block {
        font-size: 2.5rem;
        text-align: left;
        font-weight: 500;
        font-family: 'Poppins';
        line-height: 60px;
        padding: 0 50px;
    }
    .footer-login-sub-block {
        font-size: 1.5rem;
        text-align: left;
        font-weight: 500;
        font-family: 'Poppins';
        line-height: 60px;
        padding: 0 50px;
    }
    .presale-input-field {
        background: transparent;
        border: 3px solid #fff;
        border-radius: 15px;
        width: 400px;
        font-size: 1.6rem;
        color: #ffffff;
        padding: 5px 15px;
    }
    .footer-sub {
        margin-left: 50px;
        margin-top: 20px;
    }
    .footer-link {
        font-family: Poppins;
        font-size: 1.2rem;
        margin-bottom: 10px;
        margin-top: 10px;
        font-weight: 500;
        line-height: 40px;
        cursor: pointer;
        color: #fff;
        display: inline-block;
        width: 100%;
    }
    .footer-logo {
        max-width: 250px;
        cursor: pointer;
        width: 100%;
    }
    .footer-social-media {
        width: 60px;
    }
    .logo-link {
        margin-left: 30px;
        
    }
    section {
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
    }
    #wrapper.front-wrap {
        z-index: 0;
    }
    .sub-btn {
        display: inline-block;
        margin-left: -35px;
        margin-top: -10px;
        cursor: pointer;
    }
    .social-logo {
        margin-bottom: 20px;
    }
    .mobile-nav-wrapper {
        position: fixed;
        background: #13293E;
        font-size: 1.4rem;
        text-align: left;
        padding-top: 40px;
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 30px;
        top: 0;
        left: -300px;
        width: 300px;
        transition: all 0.3s linear;
        -moz-transition: all 0.3s linear; /* Firefox 4 */
        -webkit-transition: all 0.3s linear; /* Safari and Chrome */
        -o-transition: all 0.3s linear; /* Opera */
        -ms-transition: all 0.3s linear; /* Explorer 10 */
    }
    .mobile-nav-wrapper.on {
        left: 0;
    }
    .mobile-nav-wrapper a {
        color: #fff;
        font-weight: 500;
        font-family: Poppins;
        line-height: 70px;
        display: inline-block;
        width: 100%;
    }
    .social-icons-mobile {
        margin-left: -10px;
        margin-top: 150px;
    }
    @media (max-width: 991px) {
        .footer-inner {
            margin-bottom: 30px;
            text-align: center;
        }
        .footer-sub-block {
            text-align: center;
            font-size: 1.5rem;
            line-height: 35px;
        }
        .logo-img {
            width: 100%;
        }
        .logo {
            max-width: 250px;
            order: 2;
            margin-left: auto;
            margin-right: 20px;
        }
        .header-inner {
            align-items: flex-start;
            padding-top: 20px;
        }
        .logo-link {
            margin-left: 0;
        }
        .mobile-nav {
            font-size: 2rem;
            margin-left: 20px;
            cursor: pointer;
        }
        .footer-sub {
            margin-left: 0;
            margin-right: 0;
            width: 100%;
            max-width: 300px;
            font-size: 1.4rem;
        }
        .main-header {
            height: 80px;
        }
        .footer-link {
            font-size: 1rem;
            line-height: 30px;
        }
    }
    @media only screen and  (max-width: 1100px) {
        .header-col {
            font-size: 1rem;
        }
    }
</style>