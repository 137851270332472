<template>
    <div class="container">
        <section @mouseenter="discoverOn = true" @mouseleave="discoverOn = false" v-if="!isLoggedin">
            <img class="img-responsive hidden-xs hidden-sm hidden-md" src="/images/assets/banner_landingpage.jpg">
            <img class="hidden-lg img-responsive" src="/images/assets/from-background.jpeg">
            <transition name="fade">
                <div class="discover" v-show="(discoverOn && !isLoggedin) || isMobile()">
                    <img class="discover-icon" src="/images/assets/vector_1.svg">
                    <h2 class="discover-content">A NEW WAY OF REAL ESTATE<br>ANALYTICS HAS ARRIVED</h2>
                    <a v-if="loggedinEmail && !role" href="#package" class="btn hollow">DISCOVER</a>
                    <a v-else href="/presale-database" class="btn hollow">DISCOVER</a>
                </div>
            </transition>
        </section>
        <section class="no-secret-section" v-if="!isLoggedin">
            <div class="row flex-center">
                <div class="col-lg-6"><img class="img-responsive" @mouseleave="firstImg = '/images/assets/getstarted_hover1_landingpage.png'" @mouseover="firstImg = '/images/assets/getstarted_hover2_landingpage.png'" :src="firstImg"></div>
                <div class="col-lg-6 text-center">
                    <div class="no-secret d-inline-block">
                        <h2>THERE IS NO SECRET<br>TO SUCCESS.</h2>
                        <p class="no-secret-content">Nothing but <b>data</b> and <b>facts</b>. Real Property Data is an analytics tool that integrates <b>real-time</b>, <b>in-depth</b>, and <b>accurate</b> property information in one platform.</p>
                        <a href="/presale-database" class="btn mt-20 hollow">GET STARTED</a>
                    </div>
                </div>
            </div>
        </section>
        <section class="premium-section" id="feature" v-if="!isLoggedin">
            <div class="premium-wrapper">
                <h2>ACCESS TO PREMIUM<br class="hidden-lg"> REAL ESTATE <br class="hidden-md hidden-xs hidden-sm">DATA<br class="hidden-lg"> LIKE NO OTHER</h2>
                <div class="row flex-center premium-block">
                    <div class="col-lg-6">
                        <div class="row">
                            <img class="premium-icon" src="/images/assets/inplanning.png">
                            <div>
                                <h3>IN PLANNING</h3>
                                <p class="premium-content">
                                    Discover projects under early development
                                </p>
                            </div>
                        </div>
                        <div class="row">
                            <img class="premium-icon" src="/images/assets/sellingsoon_leasingsoon.png">
                            <div>
                                <h3>SELLING SOON</h3>
                                <p class="premium-content">
                                    View upcoming projects for launch release
                                </p>
                            </div>
                        </div>
                        <div class="row">
                            <img class="premium-icon" src="/images/assets/nowselling_nowleasing.png">
                            <div>
                                <h3>NOW SELLING</h3>
                                <p class="premium-content">
                                    Track ongoing projects available
                                </p>
                            </div>
                        </div>
                        <div class="row">
                            <img class="premium-icon" src="/images/assets/soldout_leasedout.png">
                            <div>
                                <h3>SOLD OUT</h3>
                                <p class="premium-content">
                                    Keep track of sold out projects
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="row">
                            <img class="premium-icon" src="/images/assets/sellingsoon_leasingsoon.png">
                            <div>
                                <h3>LEASING SOON</h3>
                                <p class="premium-content">
                                    Find leased projects under early development
                                </p>
                            </div>
                        </div>
                        <div class="row">
                            <img class="premium-icon" src="/images/assets/nowselling_nowleasing.png">
                            <div>
                                <h3>NOW LEASING</h3>
                                <p class="premium-content">
                                    Monitor ongoing leased  projects available 
                                </p>
                            </div>
                        </div>
                        <div class="row">
                            <img class="premium-icon" src="/images/assets/soldout_leasedout.png">
                            <div>
                                <h3>LEASED OUT</h3>
                                <p class="premium-content">
                                    Keep track of leased out projects
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="business-growth" v-if="!isLoggedin">
            <div class="row flex-center">
                <div class="col-lg-6 text-center business-block-text">
                    <div class="growth-wrapper d-inline-block">
                        <h2>ACCELERATE<br>BUSINESS GROWTH</h2>
                        <p class="growth-content">A revolutionary source tool created for developers, lenders, private & public equity firms, commercial and residential brokerages and brokers/agents alike. Elevate your business to the next level.</p>
                        <a href="#demo" class="btn hollow mt-20">VIEW DEMO</a>
                    </div>
                </div>
                <div class="col-lg-6 business-block-img">
                    <div v-if="accelerate" class="accelerate-block depth">
                        <div class="color-pink">IN-DEPTH</div>
                        <div>Property<br>Reports</div>
                    </div>
                    <div v-else class="accelerate-block know">
                        <div class="color-pink">+</div>
                        <div>Construction<br>Photo's</div>
                    </div>
                    <div v-if="accelerate" class="accelerate-block listing">
                        <div class="color-pink">DETAILED</div>
                        <div>Unit<br>Pricing</div>
                    </div>
                    <div v-else class="accelerate-block view">
                        <div class="color-pink">+</div>
                        <div>Floor<br>Plans</div>
                    </div>
                    <div v-if="accelerate" class="accelerate-block units">
                        <div class="color-pink">TIMELY</div>
                        <div>Project<br>Updates</div>
                    </div>
                    <div v-else class="accelerate-block access">
                        <div class="color-pink">+</div>
                        <div>Planning<br>Documents</div>
                    </div>
                    <img @mouseover="accelerate = false" @mouseleave="accelerate = true" class="growth-img" src="/images/assets/acceleratebusinessgrowthsection_image_landingpage.png">
                </div>
            </div>
        </section>
        <section class="demo-review" id="demo">
            <h2 v-show="!isLoggedin">DEMO PREVIEW</h2>
            
            <div class="demo-wrapper">
                <div v-if="role" class="logo text-left">
                    <a class="logo-link" href="/"><img class="logo-img" src="/images/assets/logo/RPD_logotype_skyblue.png"></a>
                </div>
                <div v-if="role == 'plan_a'" class="row">
                    <div class="col-sm-12"><div style="font-weight: 700; font-size: 3rem; font-family: Poppins; margin-bottom: 30px;">LET'S START!</div></div>
                </div>
                <a v-if="role != 'plan_a'" class="listing-map" href="/listing"><img class="map-icon" src="/images/assets/googlemap_icon.png"></a>
                <b-tabs style="clear: left;">
                    <b-tab title="Projects" active>
                        <!-- listsearch-input-wrap  -->  
                        <div class="row">
                            <div class="col-lg-6 pt-20">
                                <div class="row mt-20">
                                    <div class="col-sm-12 text-left">
                                        <h3 class="filter-label">Location</h3>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="listsearch-input-text col-sm-12" id="autocomplete-container">
                                        <input v-on:keyup.enter="onGo" type="text" placeholder="Destination , Area , Street" id="autocomplete-input" class="demo-input" v-model="listSearch"/>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12">
                                        <autocomplete
                                            :search="devSearch"
                                            placeholder="Developer"
                                            aria-label="Developer"
                                            :get-result-value="getResultValue"
                                            @submit="onSubmit"
                                        ></autocomplete>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 text-left">
                                        <h3 class="filter-label">Project Type</h3>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xs-6">
                                        <div class="type-filter-button-sm" @click="toggleBool('aptIsActive')" :class="{selected: aptIsActive}">
                                            <div>Apartment</div>
                                            <svg class="apt-check filter-button-check" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="5" cy="5" r="5" fill="#4D7CE1"/>
                                                <path d="M2.77772 4.99991L4.24997 6.66361L7.22221 3.33323" stroke="white" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </div>
                                    </div>
                                    <div class="col-xs-6">
                                        <div class="type-filter-button-sm" @click="toggleBool('mixedUseAptIsActive')" :class="{selected: mixedUseAptIsActive}">
                                            <div>Mixed-Use Apartment</div>
                                            <svg class="mixed-user-check filter-button-check" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="5" cy="5" r="5" fill="#4D7CE1"/>
                                                <path d="M2.77772 4.99991L4.24997 6.66361L7.22221 3.33323" stroke="white" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xs-6">
                                        <div class="type-filter-button-sm" @click="toggleBool('townhomeIsActive')" :class="{selected: townhomeIsActive}">
                                            <div>Townhome</div>
                                            <svg class="townhome-check filter-button-check" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="5" cy="5" r="5" fill="#4D7CE1"/>
                                                <path d="M2.77772 4.99991L4.24997 6.66361L7.22221 3.33323" stroke="white" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </div>
                                    </div>
                                    <div class="col-xs-6">
                                        <div class="type-filter-button-sm" @click="toggleBool('stackedTownhomeIsActive')" :class="{selected: stackedTownhomeIsActive}">
                                            <div>Stacked Townhome</div>
                                            <svg class="stacked-townhome-check filter-button-check" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="5" cy="5" r="5" fill="#4D7CE1"/>
                                                <path d="M2.77772 4.99991L4.24997 6.66361L7.22221 3.33323" stroke="white" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xs-6">
                                        <div class="type-filter-button-sm" @click="toggleBool('commercialIsActive')" :class="{selected: commercialIsActive}">
                                            <div>Commercial</div>
                                            <svg class="stacked-townhome-check filter-button-check" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="5" cy="5" r="5" fill="#4D7CE1"/>
                                                <path d="M2.77772 4.99991L4.24997 6.66361L7.22221 3.33323" stroke="white" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </div>
                                    </div>
                                    <div class="col-xs-6">
                                        <div class="type-filter-button-sm" @click="toggleBool('industrialIsActive')" :class="{selected: industrialIsActive}">
                                            <div>Industrial</div>
                                            <svg class="stacked-townhome-check filter-button-check" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="5" cy="5" r="5" fill="#4D7CE1"/>
                                                <path d="M2.77772 4.99991L4.24997 6.66361L7.22221 3.33323" stroke="white" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xs-6">
                                        <div class="type-filter-button-sm" @click="toggleBool('podiumIsActive')" :class="{selected: podiumIsActive}">
                                            <div>Podium</div>
                                            <svg class="mixed-user-check filter-button-check" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="5" cy="5" r="5" fill="#4D7CE1"/>
                                                <path d="M2.77772 4.99991L4.24997 6.66361L7.22221 3.33323" stroke="white" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </div>
                                    </div>
                                    <div class="col-xs-6">
                                        <div class="type-filter-button-sm" @click="toggleBool('rowhomeIsActive')" :class="{selected: rowhomeIsActive}">
                                            <div>Rowhome</div>
                                            <svg class="stacked-townhome-check filter-button-check" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="5" cy="5" r="5" fill="#4D7CE1"/>
                                                <path d="M2.77772 4.99991L4.24997 6.66361L7.22221 3.33323" stroke="white" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 pt-20">
                                <div class="row mt-20">
                                    <div class="col-sm-12 text-left">
                                        <h3 class="filter-label">City</h3>
                                        <div class="filter-checkbox mt-10">
                                            <treeselect v-model="citiesSelected" :multiple="true" :flat="true" :options="cityoptions" :limit="3" />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 text-left">
                                        <h3 class="filter-label">General Unit Type</h3>
                                        <div class="filter-checkbox mt-10">
                                            <treeselect v-model="gutSelected" :multiple="true" :flat="true" :options="gutoptions" :autoSelectDescendants="true" :limit="3" />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 text-left">
                                        <h3 class="filter-label">Project Status</h3>
                                        <div class="filter-select">
                                            <select data-placeholder="Select a Status" class="better-select" id="step3" multiple v-if="role != 'plan_a'"></select>
                                            <select data-placeholder="Select a Status" class="better-select" id="step3" v-else></select>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" v-if="role != 'plan_a'">
                                    <div class="col-sm-12 text-left">
                                        <h3 class="filter-label">Construction Status</h3>
                                        <div v-if="role != 'plan_a'" class="filter-select">
                                            <select data-placeholder="Select Status" class="better-select" id="step4" multiple>
                                                <option value="2899">Pre-Construction</option>
                                                <option value="2900">In Process</option>
                                                <option value="2905">Complete</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" v-if="role != 'plan_a'">
                                    <div class="col-sm-12 text-left">
                                        <h3 class="filter-label">Report Field</h3>
                                        <div v-if="role != 'plan_a'" class="filter-select mt-10">
                                            <select class="better-select" id="step5">
                                                <option value="projectTotal">Project Report</option>
                                                <option value="projectAvailability">Current Availability</option>
                                                <option value="planningReport">Planning Report</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-12">
                                <div class="filter-update hollow" @click="onReport">SEARCH</div>
                            </div>
                        </div>
                        <div class="row" v-if="role">
                            <div class="col-sm-12">
                                <div class="text-left" style="font-size: 1.2rem; font-weight: 600;" @click="logout()">LOGOUT</div>
                            </div>
                        </div>
                        <!-- listsearch-input-wrap end -->
                    </b-tab>
                    <b-tab title="Planning" :disabled="role == 'plan_a'">
                        <div class="row">
                            <div class="col-lg-6 pt-20">
                                <div class="row">
                                    <div class="col-sm-12 text-left">
                                        <h3 class="filter-label">Rezoning</h3>
                                        <div class="filter-checkbox mt-10">
                                            <treeselect v-model="rezoningSelected" :multiple="true" :flat="true" :options="rezoningOptions" :autoSelectDescendants="true"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 pt-20">
                                <div class="row">
                                    <div class="col-sm-12 text-left">
                                        <h3 class="filter-label">OCP Amendment</h3>
                                        <div class="filter-checkbox mt-10">
                                            <treeselect v-model="ocpSelected" :multiple="true" :flat="true" :options="rezoningOptions" :autoSelectDescendants="true"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6 pt-20">
                                <div class="row">
                                    <div class="col-sm-12 text-left">
                                        <h3 class="filter-label">Development Permit</h3>
                                        <div class="filter-checkbox mt-10">
                                            <treeselect v-model="dpSelected" :multiple="true" :flat="true" :options="dpOptions" :autoSelectDescendants="true"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 pt-20">
                                <div class="row">
                                    <div class="col-sm-12 text-left">
                                        <h3 class="filter-label">Development Variance Permit</h3>
                                        <div class="filter-checkbox mt-10">
                                            <treeselect v-model="dvpSelected" :multiple="true" :flat="true" :options="dpOptions" :autoSelectDescendants="true"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6 pt-20">
                                <div class="row">
                                    <div class="col-sm-12 text-left">
                                        <h3 class="filter-label">Building Permit</h3>
                                        <div class="filter-checkbox mt-10">
                                            <treeselect v-model="bpSelected" :multiple="true" :flat="true" :options="bpOptions" :autoSelectDescendants="true"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 pt-20">
                                <div class="row">
                                    <div class="col-sm-12 text-left">
                                        <h3 class="filter-label">Subdivision</h3>
                                        <div class="filter-checkbox mt-10">
                                            <treeselect v-model="subSelected" :multiple="true" :flat="true" :options="bpOptions" :autoSelectDescendants="true"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6 pt-20">
                                <div class="row">
                                    <div class="col-sm-12 text-left">
                                        <h3 class="filter-label">Project Type</h3>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xs-6">
                                        <div class="type-filter-button-sm" @click="toggleBool('aptIsActive')" :class="{selected: aptIsActive}">
                                            <div>Apartment</div>
                                            <svg class="apt-check filter-button-check" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="5" cy="5" r="5" fill="#4D7CE1"/>
                                                <path d="M2.77772 4.99991L4.24997 6.66361L7.22221 3.33323" stroke="white" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </div>
                                    </div>
                                    <div class="col-xs-6">
                                        <div class="type-filter-button-sm" @click="toggleBool('mixedUseAptIsActive')" :class="{selected: mixedUseAptIsActive}">
                                            <div>Mixed-Use Apartment</div>
                                            <svg class="mixed-user-check filter-button-check" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="5" cy="5" r="5" fill="#4D7CE1"/>
                                                <path d="M2.77772 4.99991L4.24997 6.66361L7.22221 3.33323" stroke="white" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xs-6">
                                        <div class="type-filter-button-sm" @click="toggleBool('townhomeIsActive')" :class="{selected: townhomeIsActive}">
                                            <div>Townhome</div>
                                            <svg class="townhome-check filter-button-check" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="5" cy="5" r="5" fill="#4D7CE1"/>
                                                <path d="M2.77772 4.99991L4.24997 6.66361L7.22221 3.33323" stroke="white" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </div>
                                    </div>
                                    <div class="col-xs-6">
                                        <div class="type-filter-button-sm" @click="toggleBool('stackedTownhomeIsActive')" :class="{selected: stackedTownhomeIsActive}">
                                            <div>Stacked Townhome</div>
                                            <svg class="stacked-townhome-check filter-button-check" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="5" cy="5" r="5" fill="#4D7CE1"/>
                                                <path d="M2.77772 4.99991L4.24997 6.66361L7.22221 3.33323" stroke="white" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xs-6">
                                        <div class="type-filter-button-sm" @click="toggleBool('commercialIsActive')" :class="{selected: commercialIsActive}">
                                            <div>Commercial</div>
                                            <svg class="stacked-townhome-check filter-button-check" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="5" cy="5" r="5" fill="#4D7CE1"/>
                                                <path d="M2.77772 4.99991L4.24997 6.66361L7.22221 3.33323" stroke="white" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </div>
                                    </div>
                                    <div class="col-xs-6">
                                        <div class="type-filter-button-sm" @click="toggleBool('industrialIsActive')" :class="{selected: industrialIsActive}">
                                            <div>Industrial</div>
                                            <svg class="stacked-townhome-check filter-button-check" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="5" cy="5" r="5" fill="#4D7CE1"/>
                                                <path d="M2.77772 4.99991L4.24997 6.66361L7.22221 3.33323" stroke="white" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xs-6">
                                        <div class="type-filter-button-sm" @click="toggleBool('podiumIsActive')" :class="{selected: podiumIsActive}">
                                            <div>Podium</div>
                                            <svg class="mixed-user-check filter-button-check" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="5" cy="5" r="5" fill="#4D7CE1"/>
                                                <path d="M2.77772 4.99991L4.24997 6.66361L7.22221 3.33323" stroke="white" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </div>
                                    </div>
                                    <div class="col-xs-6">
                                        <div class="type-filter-button-sm" @click="toggleBool('rowhomeIsActive')" :class="{selected: rowhomeIsActive}">
                                            <div>Rowhome</div>
                                            <svg class="stacked-townhome-check filter-button-check" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="5" cy="5" r="5" fill="#4D7CE1"/>
                                                <path d="M2.77772 4.99991L4.24997 6.66361L7.22221 3.33323" stroke="white" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 pt-20">
                                <div class="row">
                                    <div class="col-sm-12 text-left">
                                        <h3 class="filter-label">City</h3>
                                        <div class="filter-checkbox mt-10">
                                            <treeselect v-model="citiesSelected" :multiple="true" :flat="true" :options="cityoptions" :limit="3" />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 text-left">
                                        <h3 class="filter-label">General Unit Type</h3>
                                        <div class="filter-checkbox mt-10">
                                            <treeselect v-model="gutSelected" :multiple="true" :flat="true" :options="gutoptions" :autoSelectDescendants="true" :limit="3" />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 text-left">
                                        <h3 class="filter-label">Project Status</h3>
                                        <div class="filter-select">
                                            <select data-placeholder="Select a Status" class="better-select" id="stepStatus" multiple v-if="role != 'plan_a'"></select>
                                            <select data-placeholder="Select a Status" class="better-select" id="stepStatus" v-else></select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="filter-update hollow" @click="PlanningUTCGo">Search</div>
                                <div class="filter-update" style="margin-left: 20px; color:#13293E;background:#fff;" @click="PlanningGo">Planning Updates</div>
                            </div>
                        </div>
                    </b-tab>
                    <b-tab title="Units" :disabled="role == 'plan_a'">
                        <div class="row">
                            <div class="col-lg-6 pt-20">
                                <div class="row">
                                    <div class="col-sm-12 text-left">
                                        <h3 class="filter-label">City</h3>
                                        <div class="filter-checkbox mt-10">
                                            <treeselect v-model="mlsCitiesSelected" :multiple="true" :flat="true" :options="cityoptions" :limit="3"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 text-left">
                                        <h3 class="filter-label">City</h3>
                                        <div class="filter-checkbox mt-10">
                                            <treeselect v-model="gutMLSSelected" :multiple="true" :flat="true" :options="gutoptions" :autoSelectDescendants="true" :limit="3"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-20">
                                    <div class="col-sm-12 text-left">
                                        <h3 class="filter-label">Project Type</h3>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="type-filter-button-sm" @click="toggleBool('aptMLSIsActive')" :class="{selected: aptMLSIsActive}">
                                            <div>Apartment</div>
                                            <svg class="apt-check filter-button-check" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="5" cy="5" r="5" fill="#4D7CE1"/>
                                                <path d="M2.77772 4.99991L4.24997 6.66361L7.22221 3.33323" stroke="white" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="type-filter-button-sm" @click="toggleBool('mixedUseAptMLSIsActive')" :class="{selected: mixedUseAptMLSIsActive}">
                                            <div>Mixed-Use Apartment</div>
                                            <svg class="mixed-user-check filter-button-check" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="5" cy="5" r="5" fill="#4D7CE1"/>
                                                <path d="M2.77772 4.99991L4.24997 6.66361L7.22221 3.33323" stroke="white" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="type-filter-button-sm" @click="toggleBool('townhomeMLSIsActive')" :class="{selected: townhomeMLSIsActive}">
                                            <div>Townhome</div>
                                            <svg class="townhome-check filter-button-check" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="5" cy="5" r="5" fill="#4D7CE1"/>
                                                <path d="M2.77772 4.99991L4.24997 6.66361L7.22221 3.33323" stroke="white" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="type-filter-button-sm" @click="toggleBool('stackedTownhomeMLSIsActive')" :class="{selected: stackedTownhomeMLSIsActive}">
                                            <div>Stacked Townhome</div>
                                            <svg class="stacked-townhome-check filter-button-check" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="5" cy="5" r="5" fill="#4D7CE1"/>
                                                <path d="M2.77772 4.99991L4.24997 6.66361L7.22221 3.33323" stroke="white" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="type-filter-button-sm" @click="toggleBool('commercialMLSIsActive')" :class="{selected: commercialMLSIsActive}">
                                            <div>Commercial</div>
                                            <svg class="stacked-townhome-check filter-button-check" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="5" cy="5" r="5" fill="#4D7CE1"/>
                                                <path d="M2.77772 4.99991L4.24997 6.66361L7.22221 3.33323" stroke="white" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="type-filter-button-sm" @click="toggleBool('industrialMLSIsActive')" :class="{selected: industrialMLSIsActive}">
                                            <div>Industrial</div>
                                            <svg class="stacked-townhome-check filter-button-check" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="5" cy="5" r="5" fill="#4D7CE1"/>
                                                <path d="M2.77772 4.99991L4.24997 6.66361L7.22221 3.33323" stroke="white" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="type-filter-button-sm" @click="toggleBool('podiumMLSIsActive')" :class="{selected: podiumMLSIsActive}">
                                            <div>Podium</div>
                                            <svg class="mixed-user-check filter-button-check" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="5" cy="5" r="5" fill="#4D7CE1"/>
                                                <path d="M2.77772 4.99991L4.24997 6.66361L7.22221 3.33323" stroke="white" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="type-filter-button-sm" @click="toggleBool('rowhomeMLSIsActive')" :class="{selected: rowhomeMLSIsActive}">
                                            <div>Rowhome</div>
                                            <svg class="stacked-townhome-check filter-button-check" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="5" cy="5" r="5" fill="#4D7CE1"/>
                                                <path d="M2.77772 4.99991L4.24997 6.66361L7.22221 3.33323" stroke="white" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-sm-12 text-left">
                                        <h3 class="filter-label">Unit Size</h3>
                                    </div>
                                </div>
                                <div class="row mt-10">
                                    <div class="col-lg-6">
                                        <input class="demo-input-normal" v-model="mlsUnitMin" type="text" placeholder="Min Value">
                                    </div>
                                    <div class="col-lg-6">
                                        <input class="demo-input-normal" v-model="mlsUnitMin" type="text" placeholder="Min Value">
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 pt-20">
                                <div class="row">
                                    <div class="col-sm-12 text-left">
                                        <h3 class="filter-label">Beds</h3>
                                    </div>
                                </div>
                                <div class="row mt-10">
                                    <div class="col-lg-6">
                                        <input class="demo-input-normal" v-model="mlsBedsMin" type="text" placeholder="Min Value">
                                    </div>
                                    <div class="col-lg-6">
                                        <input class="demo-input-normal" v-model="mlsBedsMax" type="text" placeholder="Min Value">
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 text-left">
                                        <h3 class="filter-label">Baths</h3>
                                    </div>
                                </div>
                                <div class="row mt-10">
                                    <div class="col-lg-6">
                                        <input class="demo-input-normal" v-model="mlsBathsMin" type="text" placeholder="Min Value">
                                    </div>
                                    <div class="col-lg-6">
                                        <input class="demo-input-normal" v-model="mlsBathsMax" type="text" placeholder="Min Value">
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 text-left">
                                        <h3 class="filter-label">MLS Status</h3>
                                        <div class="filter-checkbox mt-10">
                                            <treeselect v-model="statusMLSSelected" :multiple="true" :flat="true" :options="MLSStatusOptions" :autoSelectDescendants="true" :limit="3" />
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-10">
                                    <div class="col-lg-6 text-left ">
                                        <h3 class="filter-label">List Date</h3>
                                        <date-picker class="demo-date" v-model="listMLSDate" type="date" range placeholder="Select date range"></date-picker>
                                    </div>
                                    <div class="col-lg-6 text-left">
                                        <h3 class="filter-label">Sold Date</h3>
                                        <date-picker class="demo-date" v-model="soldMLSDate" type="date" range placeholder="Select date range"></date-picker>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 text-left">
                                        <h3 class="filter-label">List Price</h3>
                                    </div>
                                </div>
                                <div class="row mt-10">
                                    <div class="col-lg-6">
                                        <input class="demo-input-normal" v-model="mlsListPriceMin" type="text" placeholder="Min Value">
                                    </div>
                                    <div class="col-lg-6">
                                        <input class="demo-input-normal" v-model="mlsListPriceMax" type="text" placeholder="Min Value">
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 text-left">
                                        <h3 class="filter-label">Sold Price</h3>
                                    </div>
                                </div>
                                <div class="row mt-10">
                                    <div class="col-lg-6">
                                        <input class="demo-input-normal" v-model="mlsSoldPriceMin" type="text" placeholder="Min Value">
                                    </div>
                                    <div class="col-lg-6">
                                        <input class="demo-input-normal" v-model="mlsSoldPriceMax" type="text" placeholder="Min Value">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                    <div class="filter-reset" @click="MLSReset">RESET</div>
                                    <div class="filter-update" @click="MLSGo">SEARCH</div>
                            </div>
                        </div>
                    </b-tab>
                    <b-tab title="Land" :disabled="role == 'plan_a'">
                        <div class="row">
                            <div class="col-lg-6 pt-20">
                                <div class="row">
                                    <div class="col-sm-12 text-left">
                                        <h3 class="filter-label">LTSA Plan LTSA Registration</h3>
                                        <input type="text" placeholder="LTSA Plan" class="demo-input mt-10" v-model="LTSAPlanSearch"/>
                                        <div class="filter-checkbox mt-10">
                                            <treeselect v-model="parcelStatusLandSelected" :multiple="true" :flat="true" :options="parcelStatusLandOptions" :autoSelectDescendants="true" :limit="3"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 text-left">
                                        <h3 class="filter-label">LTSA Parcel Status</h3>
                                        <div class="filter-checkbox mt-10">
                                            <treeselect v-model="parcelStatusLandSelected" :multiple="true" :flat="true" :options="parcelStatusLandOptions" :autoSelectDescendants="true" :limit="3"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 text-left">
                                        <h3 class="filter-label">LTSA PID</h3>
                                        <input class="demo-input mt-10" v-model="ltsapid" type="text" placeholder="LTSA PID">
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 pt-20">
                                <div class="row">
                                    <div class="col-sm-12 text-left">
                                        <h3 class="filter-label">Registration Type</h3>
                                        <div class="filter-checkbox mt-10">
                                            <treeselect v-model="regTypeSelected" :multiple="true" :flat="true" :options="regTypeOptions" :autoSelectDescendants="true" :limit="3"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 text-left">
                                        <h3 class="filter-label">Registration Date</h3>
                                        <date-picker class="demo-date" v-model="registrationLandDate" type="date" range placeholder="Select date range"></date-picker>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 text-left">
                                        <h3 class="filter-label">Land Sale Price</h3>
                                    </div>
                                </div>
                                <div class="row mt-10">
                                    <div class="col-lg-6">
                                        <input class="demo-input-normal" v-model="landSalePriceMin" type="text" placeholder="Min Value">
                                    </div>
                                    <div class="col-lg-6">
                                        <input class="demo-input-normal" v-model="landSalePriceMax" type="text" placeholder="Min Value">
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 text-left">
                                        <h3 class="filter-label">LTSA Lot Size (sq ft)</h3>
                                    </div>
                                </div>
                                <div class="row mt-10">
                                    <div class="col-lg-6">
                                        <input class="demo-input-normal" v-model="landLotSizeMin" type="text" placeholder="Min Value">
                                    </div>
                                    <div class="col-lg-6">
                                        <input class="demo-input-normal" v-model="landLotSizeMax" type="text" placeholder="Min Value">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="filter-reset" @click="LandReset">RESET</div>
                                <div class="filter-update" @click="LandGo">SEARCH</div>
                            </div>
                        </div>
                    </b-tab>
                </b-tabs>
            </div>
        </section>
        <section class="package" id="package" v-if="!isLoggedin">
            <h2 class="package-title">PACKAGES</h2>
            <div class="row package-wrapper">
                <div class="col-lg-6">
                    <div class="package-block">
                        <h3>Presale Database</h3>
                        <!-- <h4 style="text-decoration: line-through;">$49.99/monthly</h4>
                        <h4 style="text-decoration: none;">$29.99/monthly</h4> -->
                    </div>
                    <div class="package-content">
                        <div class="package-row">
                            <img class="pkg-img" src="/images/assets/checkmark_icon.png">
                            <span>For Brokers and Agents</span>
                        </div>
                        <div class="package-row">
                            <img class="pkg-img" src="/images/assets/checkmark_icon.png">
                            <span>Apartments, Mixed-Use Apartments, Townhouse</span>
                        </div>
                        <div class="package-row">
                            <img class="pkg-img" src="/images/assets/checkmark_icon.png">
                            <span>Market Strata</span>
                        </div>
                        <div class="package-row">
                            <img class="pkg-img" src="/images/assets/checkmark_icon.png">
                            <span>In Planning, Selling Soon, Now Selling, Sold Out</span>
                        </div>
                        <div class="package-row">
                            <img class="pkg-img" src="/images/assets/checkmark_icon.png">
                            <span>Surrey</span>
                        </div>
                    </div>
                    <stripe-checkout
                        ref="checkoutRef"
                        mode="subscription"
                        :customerEmail="loggedinEmail"
                        :pk="publishableKey"
                        :line-items="lineItems"
                        :success-url="successURL"
                        :cancel-url="cancelURL"
                        @loading="v => loading = v"
                        />
                    <div class="btn mt-20 hollow" @click="planSubmit">SUBSCRIBE</div>
                </div>
                <div class="col-lg-6">
                    <div class="package-block">
                        <h3>RPD Enterprise</h3>
                        <!-- <h4>$99.99/monthly</h4> -->
                    </div>
                    <div class="package-content">
                        <div class="package-row">
                            <img class="pkg-img" src="/images/assets/checkmark_icon.png">
                            <span>Access to advanced data used for full real estate cycle</span>
                        </div>
                        <div class="package-row">
                            <img class="pkg-img" src="/images/assets/checkmark_icon.png">
                            <span>Commercial and Industrial</span>
                        </div>
                        <div class="package-row">
                            <img class="pkg-img" src="/images/assets/checkmark_icon.png">
                            <span>Market Rental & Below-Market Rental, Below-Market Strata</span>
                        </div>
                        <div class="package-row">
                            <img class="pkg-img" src="/images/assets/checkmark_icon.png">
                            <span>Land, Leasing Soon, Now Leasing, Leased Out</span>
                        </div>
                    </div>
                    <div class="btn mt-20 hollow color-pink">COMING SOON</div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
    import {BTabs, BTab} from 'bootstrap-vue';
    import Autocomplete from '@trevoreyre/autocomplete-vue'
    import '@trevoreyre/autocomplete-vue/dist/style.css'
    import Treeselect from '@riophae/vue-treeselect'
    import '@riophae/vue-treeselect/dist/vue-treeselect.css'
    import {BACKEND_URL} from '../variables.js';
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    import { StripeCheckout } from '@vue-stripe/vue-stripe';

    export default {
        components: { 
            'b-tabs': BTabs,
            'b-tab': BTab,
            Autocomplete,
            Treeselect,
            DatePicker,
            StripeCheckout
        },
        directives: {
        },
        data() {
            // this.publishableKey = 'pk_test_VKUVHkXdQpC8YHKijf8GnWhn';
            this.publishableKey = 'pk_live_51KNl2KE1yVi4Nb4L8KtyhYPYQUHobf7FcZKqPDAzU6DyTOz98Pg39tV7K2SmULGohfJVU6OgpqRjeQRKthSV0UDO00pjjjhwGG';
            return {
                query: this.$route.query,
                projects: [],
                locations: [],
                all_types: [
                    {name: 'Apartments', value: 'apt'},
                    {name: 'Townhome', value: '2870'}
                ],
                all_type: null,
                all_city: null,
                all_status: null,
                new_type: null,
                new_city: null,
                new_status: null,
                listSearch: '',
                initBeforeSearch: true,
                mapSwitch: false,
                types: [],
                sortColumn: 'address',
                sortBy: 'sort-ad',
                orderAsc: true,
                is_loading: true,
                rzStatus: [],
                bpStatus: [],
                dpStatus: [],
                rzSubDate: [],
                rzAppDate: [],
                dpSubDate: [],
                dpIssueDate: [],
                bpSubDate: [],
                bpIssueDate: [],
                aptIsActive: false,
                townhomeIsActive: false,
                mixedUseAptIsActive: false,
                podiumIsActive: false,
                stackedTownhomeIsActive: false,
                commercialIsActive: false,
                industrialIsActive: false,
                rowhomeIsActive: false,
                aptMLSIsActive: false,
                townhomeMLSIsActive: false,
                mixedUseAptMLSIsActive: false,
                podiumMLSIsActive: false,
                stackedTownhomeMLSIsActive: false,
                commercialMLSIsActive: false,
                industrialMLSIsActive: false,
                rowhomeMLSIsActive: false,
                mapActive: true,
                menuStatus: true,
                mapBox: [],
                dateSort: 'app',
                dateNameSort: '',
                constructionFilter: '',
                rzFilter: '',
                dpFilter: '',
                bpFilter: '',
                dpDateSort: false,
                bpDateSort: false,
                rzDateSort: false,
                mapPop: false,
                searchStart: false,
                citiesSelected: [],
                mlsCitiesSelected: [],
                landCitiesSelected: [],
                gutSelected: [],
                gutMLSSelected: [],
                cityoptions: [],
                gutoptions: [],
                mlsBedsMax: null,
                mlsBedsMin: null,
                mlsUnitMax: null,
                mlsBathsMin: null,
                mlsBathsMax: null,
                mlsUnitMin: null,
                listMLSDate: [],
                soldMLSDate: [],
                mlsListPriceMin: null,
                mlsListPriceMax: null,
                mlsSoldPriceMin: null,
                mlsSoldPriceMax: null,
                developerSearch: null,
                MLSStatusOptions: [
                    {id: 8197, label: 'Active'},
                    {id: 8198, label: 'Sold'},
                    {id: 8205, label: 'Terminated'},
                    {id: 8203, label: 'Withdrawn'},
                ],
                parcelStatusLandOptions: [
                    {id: 11195, label: 'Active'},
                    {id: 11196, label: 'Cancelled'},
                    {id: 11197, label: 'Inactive'}
                ],
                regTypeOptions: [
                    {id: 11249, label: 'Title Cancel'},
                    {id: 11191, label: 'Title Transfer'},
                    {id: 11190, label: 'Mortgage'}
                ],
                rezoningOptions: [
                    {id: 0, label: 'Not Submitted'},
                    {id: 10943, label: 'In Process'},
                    {id: 10944, label: 'Approved'},
                    {id: 10945, label: 'Adopted'},
                    {id: 10946, label: 'Denied'}
                ],
                dpOptions: [
                    {id: 0, label: 'Not Submitted'},
                    {id: 10943, label: 'In Process'},
                    {id: 10944, label: 'Approved'},
                    {id: 10947, label: 'Issued'}
                ],
                bpOptions: [
                    {id: 0, label: 'Not Submitted'},
                    {id: 10943, label: 'In Process'},
                    {id: 10944, label: 'Approved'},
                ],
                LTSAPlanSearch: null,
                LTSARegistrationSearch: null,
                parcelStatusLandSelected: [],
                statusMLSSelected: [],
                regTypeSelected: [],
                ltsapid: null,
                registrationLandDate: [],
                landSalePriceMin: null,
                landSalePriceMax: null,
                landLotSizeMin: null,
                landLotSizeMax: null,
                subSelected: [],
                bpSelected: [],
                dvpSelected: [],
                dpSelected: [],
                ocpSelected: [],
                rezoningSelected: [],
                reportoptions: [
                    {id:'projectTotal', label: 'Project Report', children: [
                        {id: 'sales_start_date', label: 'Sales Start Date', isDisabled: true},
                        {id: 'total_units', label: 'Total Units', isDisabled: true},
                        {id: 'rlsd_units', label: 'Rlsds Units', isDisabled: true},
                        {id: 'sold_units', label: 'Sold Units', isDisabled: true},
                        {id: 'available', label: 'Available Units', isDisabled: true},
                        {id: 'total_avg_psf', label: 'Total Average $PSF', isDisabled: true},
                        {id: 'total_avg_size', label: 'Total Avg Size', isDisabled: true},
                        {id: 'total_avg_price', label: 'Total Avg Price', isDisabled: true}
                    ]},
                    {id:'projectAvailability', label: 'Current Availability', children: [
                        {id: 'sales_start_date', label: 'Sales Start Date', isDisabled: true},
                        {id: 'total_units', label: 'Total Units', isDisabled: true},
                        {id: 'rlsd_units', label: 'Rlsds Units', isDisabled: true},
                        {id: 'sold_units', label: 'Sold Units', isDisabled: true},
                        {id: 'available', label: 'Available Units', isDisabled: true},
                        {id: 'current_avg_size', label: 'Current Avg Size', isDisabled: true},
                        {id: 'current_avg_price', label: 'Current Avg Price', isDisabled: true},
                        {id: 'current_avg_psf', label: 'Current Average $PSF', isDisabled: true}
                    ]},
                    {id:'planningReport', label: 'Planning Report ', children: [
                        {id: 'rezoning_status', label: 'Rezoning Status', isDisabled: true},
                        {id: 'dp_status', label: 'DP status', isDisabled: true},
                        {id: 'bp_status', label: 'BP Status', isDisabled: true},
                    ]}
                ],
                polyGon: false,
                role: '',
                loading: false,
                lineItems: [
                    {
                        // price: 'price_1KyJagE1yVi4Nb4LiHxCNmjL', // The id of the recurring price you created in your Stripe dashboard
                        price: 'price_1KSK75E1yVi4Nb4LL94YK46M',
                        quantity: 1,
                    },
                ],
                successURL: 'https://www.realpropertydata.ca/backend/stripe/checkout_session',
                cancelURL: 'https://www.realpropertydata.ca/',
                isLoggedin: false,
                firstImg: '/images/assets/getstarted_hover1_landingpage.png',
                accelerate: true,
                discoverOn: false,
                loggedinEmail: ''
            }
        },
        methods: {
            isMobile() {
                if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || $(window).width() < 992) {
                    return true
                } else {
                    return false
                }
            },
            logout() {
                $.get(BACKEND_URL + 'api/api_logout_user', (data, status) => {
                    this.$router.push({ path: '/'});
                    this.$router.go();
                });
            },
            planSubmit () {
                if (this.loggedinEmail) {
                    this.$refs.checkoutRef.redirectToCheckout();
                }
                else {
                    window.location.href = "/presale-database";
                }
            },
            toggleBool(val) {
                this[val] = !this[val];
            },
            devSearch(input) {
                const url = BACKEND_URL + `api/developers?search=${encodeURI(input)}`

                return new Promise((resolve) => {
                    if (input.length < 3) {
                    return resolve([])
                    }

                    fetch(url)
                        .then((response) => response.json())
                        .then((data) => {
                        resolve(data)
                    })
                })
            },
            getResultValue(result) {
                return result.title
            },
            onSubmit(result) {
                this.developerSearch = result.nid
            },
            onReport() {
                if (!this.role) {
                    return;
                }
                const vm = this;
                let q = {};
                q['type[]'] = new Array();
                q['city[]'] = new Array();
                q['status[]'] = new Array();
                q['constructionstatus[]'] = new Array();
                q['unittype[]'] = new Array();
                if (this.listSearch) {
                    q['search'] = this.listSearch;
                }
                if (this.developerSearch) {
                    q['developer'] = this.developerSearch;
                }
                q['report[]'] = new Array();
                if (this.aptIsActive) {
                    q['type[]'].push('2886');
                }
                if (this.townhomeIsActive) {
                     q['type[]'].push('2870');
                }
                if (this.mixedUseAptIsActive) {
                     q['type[]'].push('2884');
                }
                if (this.podiumIsActive) {
                     q['type[]'].push('5910');
                }
                if (this.stackedTownhomeIsActive) {
                     q['type[]'].push('3479');
                }
                if (this.commercialIsActive) {
                     q['type[]'].push('2962');
                }
                if (this.industrialIsActive) {
                     q['type[]'].push('3047');
                }
                if (this.rowhomeIsActive) {
                     q['type[]'].push('6240');
                }
                $('#step1').next().find('ul.list .selected').each(function() {
                        q['type[]'].push($(this).attr('data-value'));
                });
                $('#step3').next().find('ul.list .selected').each(function() {
                        q['status[]'].push($(this).attr('data-value'));
                });
                $('#step4').next().find('ul.list .selected').each(function() {
                        q['constructionstatus[]'].push($(this).attr('data-value'));
                });
                if (this.rzSubDate) {
                    q['rzSubDate[]'] = new Array();
                }
                if (this.role == 'admin') {
                    var reportSelected = ''
                    $('#step5').next().find('ul.list .selected').each(function() {
                        reportSelected = $(this).attr('data-value');
                    });
                    q['unittype[]'] = this.gutSelected;
                    q['city[]'] = this.citiesSelected;
                    if (reportSelected == 'projectTotal') {
                        [
                            {id: 'sales_start_date', label: 'Sales Start Date', isDisabled: true},
                            {id: 'total_units', label: 'Total Units', isDisabled: true},
                            {id: 'rlsd_units', label: 'Rlsds Units', isDisabled: true},
                            {id: 'sold_units', label: 'Sold Units', isDisabled: true},
                            {id: 'available', label: 'Available Units', isDisabled: true},
                            {id: 'total_avg_psf', label: 'Total Average $PSF', isDisabled: true},
                            {id: 'total_avg_size', label: 'Total Avg Size', isDisabled: true},
                            {id: 'total_avg_price', label: 'Total Avg Price', isDisabled: true},
                            {id: 'updated', label: 'Updated', isDisabled: true},
                            {id: 'project_report', label: 'Project Report', isDisabled: true}
                        ].forEach(function(val, i){
                            q['report[]'].push(val.id);
                        });
                    }
                    else if (reportSelected == 'projectAvailability') {
                        [
                            {id: 'sales_start_date', label: 'Sales Start Date', isDisabled: true},
                            {id: 'total_units', label: 'Total Units', isDisabled: true},
                            {id: 'rlsd_units', label: 'Rlsds Units', isDisabled: true},
                            {id: 'sold_units', label: 'Sold Units', isDisabled: true},
                            {id: 'available', label: 'Available Units', isDisabled: true},
                            {id: 'current_avg_size', label: 'Current Avg Size', isDisabled: true},
                            {id: 'current_avg_price', label: 'Current Avg Price', isDisabled: true},
                            {id: 'current_avg_psf', label: 'Current Average $PSF', isDisabled: true},
                            {id: 'current_availability', label: 'Current Availability', isDisabled: true}
                        ].forEach(function(val, i){
                            q['report[]'].push(val.id);
                        });
                    }
                    else if (reportSelected == 'planningReport') {
                        [
                            {id: 'rezoning_status', label: 'Rezoning Status', isDisabled: true},
                            {id: 'dp_status', label: 'DP status', isDisabled: true},
                            {id: 'bp_status', label: 'BP Status', isDisabled: true},
                            {id: 'planning_report', label: 'Planning Report', isDisabled: true}
                        ].forEach(function(val, i){
                            q['report[]'].push(val.id);
                        });
                    }
                }
                else {
                    q['unittype[]'] = [11153, 2952];
                    q['city[]'] = [2887];
                    var status_selected = 'now_selling';
                    $('#step3').next().find('ul.list .selected').each(function() {
                        status_selected = $(this).attr('data-value');
                    });
                    if (status_selected == 'now_selling') {
                        [
                            {id: 'sales_start_date', label: 'Sales Start Date', isDisabled: true},
                            {id: 'total_units', label: 'Total Units', isDisabled: true},
                            {id: 'rlsd_units', label: 'Rlsds Units', isDisabled: true},
                            {id: 'sold_units', label: 'Sold Units', isDisabled: true},
                            {id: 'available', label: 'Available Units', isDisabled: true},
                            {id: 'total_avg_psf', label: 'Total Average $PSF', isDisabled: true},
                            {id: 'total_avg_size', label: 'Total Avg Size', isDisabled: true},
                            {id: 'total_avg_price', label: 'Total Avg Price', isDisabled: true},
                            {id: 'updated', label: 'Updated', isDisabled: true}
                        ].forEach(function(val, i){
                            q['report[]'].push(val.id);
                        });
                    }
                    else if (status_selected == 'sold_out' || status_selected == 'sold_out_2') {
                        [
                            {id: 'sales_start_date', label: 'Sales Start Date', isDisabled: true},
                            {id: 'sold_out_date', label: 'Sold Out Date', isDisabled: true},
                            {id: 'total_units', label: 'Total Units', isDisabled: true},
                            {id: 'rlsd_units', label: 'Rlsds Units', isDisabled: true},
                            {id: 'sold_units', label: 'Sold Units', isDisabled: true},
                            {id: 'available', label: 'Available Units', isDisabled: true},
                            {id: 'total_avg_psf', label: 'Total Average $PSF', isDisabled: true},
                            {id: 'total_avg_size', label: 'Total Avg Size', isDisabled: true},
                            {id: 'total_avg_price', label: 'Total Avg Price', isDisabled: true},
                        ].forEach(function(val, i){
                            q['report[]'].push(val.id);
                        });
                    }
                    else {
                        [
                            {id: 'rezoning_status', label: 'Rezoning Status', isDisabled: true},
                            {id: 'dp_status', label: 'DP status', isDisabled: true},
                            {id: 'bp_status', label: 'BP Status', isDisabled: true},
                        ].forEach(function(val, i){
                            q['report[]'].push(val.id);
                        });
                    }
                }


                this.rzSubDate.forEach(function(value, i){
                    q['rzSubDate[]'].push(vm.toTimestamp(value));
                });

                if (this.rzAppDate) {
                    q['rzAppDate[]'] = new Array();
                }
                this.rzAppDate.forEach(function(value, i){
                    q['rzAppDate[]'].push(vm.toTimestamp(value));
                });

                if (this.dpSubDate) {
                    q['dpSubDate[]'] = new Array();
                }
                this.dpSubDate.forEach(function(value, i){
                    q['dpSubDate[]'].push(vm.toTimestamp(value));
                });

                if (this.bpSubDate) {
                    q['bpSubDate[]'] = new Array();
                }
                this.bpSubDate.forEach(function(value, i){
                    q['bpSubDate[]'].push(vm.toTimestamp(value));
                });

                if (this.dpIssueDate) {
                    q['dpIssueDate[]'] = new Array();
                }
                this.dpIssueDate.forEach(function(value, i){
                    q['dpIssueDate[]'].push(vm.toTimestamp(value));
                });

                if (this.bpIssueDate) {
                    q['bpIssueDate[]'] = new Array();
                }
                this.bpIssueDate.forEach(function(value, i){
                    q['bpIssueDate[]'].push(vm.toTimestamp(value));
                });

                //  status group
                if (this.rzStatus) {
                    q['rzStatus[]'] = new Array();
                }
                this.rzStatus.forEach(function(value, i){
                    q['rzStatus[]'].push(value);
                });

                if (this.bpStatus) {
                    q['bpStatus[]'] = new Array();
                }
                this.bpStatus.forEach(function(value, i){
                    q['bpStatus[]'].push(value);
                });

                if (this.dpStatus) {
                    q['dpStatus[]'] = new Array();
                }
                this.dpStatus.forEach(function(value, i){
                    q['dpStatus[]'].push(value);
                });
                if (!vm.polyGon) {
                    localStorage.clear();
                }
                this.$router.push({ path: '/report', query: q});
                this.$router.go();
            },
            onGo() {
                if (!this.role) {
                    return;
                }
                const vm = this;
                let q = {};
                q['type[]'] = new Array();
                q['city[]'] = new Array();
                q['status[]'] = new Array();
                q['constructionstatus[]'] = new Array();
                q['unittype[]'] = new Array();
                if (this.listSearch) {
                    q['search'] = this.listSearch;
                }
                if (this.developerSearch) {
                    q['developer'] = this.developerSearch;
                }
                if (this.aptIsActive) {
                    q['type[]'].push('2886');
                }
                if (this.townhomeIsActive) {
                     q['type[]'].push('2870');
                }
                if (this.mixedUseAptIsActive) {
                     q['type[]'].push('2884');
                }
                if (this.podiumIsActive) {
                     q['type[]'].push('5910');
                }
                if (this.stackedTownhomeIsActive) {
                     q['type[]'].push('3479');
                }
                if (this.commercialIsActive) {
                     q['type[]'].push('2962');
                }
                if (this.industrialIsActive) {
                     q['type[]'].push('3047');
                }
                if (this.rowhomeIsActive) {
                     q['type[]'].push('6240');
                }
                $('#step1').next().find('ul.list .selected').each(function() {
                        q['type[]'].push($(this).attr('data-value'));
                });
                q['city[]'] = this.citiesSelected;
                $('#step3').next().find('ul.list .selected').each(function() {
                        q['status[]'].push($(this).attr('data-value'));
                });
                $('#step4').next().find('ul.list .selected').each(function() {
                        q['constructionstatus[]'].push($(this).attr('data-value'));
                });
                q['unittype[]'] = this.gutSelected;
                // date group
                if (this.rzSubDate) {
                    q['rzSubDate[]'] = new Array();
                }
                this.rzSubDate.forEach(function(value, i){
                    q['rzSubDate[]'].push(vm.toTimestamp(value));
                });

                if (this.rzAppDate) {
                    q['rzAppDate[]'] = new Array();
                }
                this.rzAppDate.forEach(function(value, i){
                    q['rzAppDate[]'].push(vm.toTimestamp(value));
                });

                if (this.dpSubDate) {
                    q['dpSubDate[]'] = new Array();
                }
                this.dpSubDate.forEach(function(value, i){
                    q['dpSubDate[]'].push(vm.toTimestamp(value));
                });

                if (this.bpSubDate) {
                    q['bpSubDate[]'] = new Array();
                }
                this.bpSubDate.forEach(function(value, i){
                    q['bpSubDate[]'].push(vm.toTimestamp(value));
                });

                if (this.dpIssueDate) {
                    q['dpIssueDate[]'] = new Array();
                }
                this.dpIssueDate.forEach(function(value, i){
                    q['dpIssueDate[]'].push(vm.toTimestamp(value));
                });

                if (this.bpIssueDate) {
                    q['bpIssueDate[]'] = new Array();
                }
                this.bpIssueDate.forEach(function(value, i){
                    q['bpIssueDate[]'].push(vm.toTimestamp(value));
                });

                //  status group
                if (this.rzStatus) {
                    q['rzStatus[]'] = new Array();
                }
                this.rzStatus.forEach(function(value, i){
                    q['rzStatus[]'].push(value);
                });

                if (this.bpStatus) {
                    q['bpStatus[]'] = new Array();
                }
                this.bpStatus.forEach(function(value, i){
                    q['bpStatus[]'].push(value);
                });

                if (this.dpStatus) {
                    q['dpStatus[]'] = new Array();
                }
                this.dpStatus.forEach(function(value, i){
                    q['dpStatus[]'].push(value);
                });
                this.$router.push({ path: '/', query: q});
                this.$router.go();
            },
            LandReset() {

            },
            PlanningReset() {
                this.subSelected = []
                this.bpSelected = []
                this.dvpSelected = []
                this.dpSelected = []
                this.ocpSelected = []
                this.rezoningSelected = []
            },
            PlanningUTCGo() {
                if (!this.role) {
                    return;
                }
                const vm = this;
                let q = {};
                q['type[]'] = new Array();
                q['city[]'] = new Array();
                q['status[]'] = new Array();
                q['unittype[]'] = new Array();
                if (this.subSelected) {
                    q['sub[]'] = this.subSelected;
                }
                if (this.bpSelected) {
                    q['bp[]'] = this.bpSelected;
                }
                if (this.dvpSelected) {
                    q['dvp[]'] = this.dvpSelected;
                }
                if (this.dpSelected) {
                    q['dp[]'] = this.dpSelected;
                }
                if (this.ocpSelected) {
                    q['ocp[]'] = this.ocpSelected;
                }
                if (this.rezoningSelected) {
                    q['rez[]'] = this.rezoningSelected;
                }
                if (this.citiesSelected) {
                    q['city[]'] = this.citiesSelected;
                }
                if (this.gutSelected) {
                    q['unittype[]'] = this.gutSelected;
                }
                if (this.aptIsActive) {
                    q['type[]'].push('2886');
                }
                if (this.townhomeIsActive) {
                     q['type[]'].push('2870');
                }
                if (this.mixedUseAptIsActive) {
                     q['type[]'].push('2884');
                }
                if (this.podiumIsActive) {
                     q['type[]'].push('5910');
                }
                if (this.stackedTownhomeIsActive) {
                     q['type[]'].push('3479');
                }
                if (this.commercialIsActive) {
                     q['type[]'].push('2962');
                }
                if (this.industrialIsActive) {
                     q['type[]'].push('3047');
                }
                if (this.rowhomeIsActive) {
                     q['type[]'].push('6240');
                }
                $('#stepStatus').next().find('ul.list .selected').each(function() {
                    q['status[]'].push($(this).attr('data-value'));
                });
                this.$router.push({ path: '/utc-report-planning', query: q});
                this.$router.go();
            },
            PlanningGo() {
                if (!this.role) {
                    return;
                }
                const vm = this;
                let q = {};
                if (this.subSelected) {
                    q['sub[]'] = this.subSelected;
                }
                if (this.bpSelected) {
                    q['bp[]'] = this.bpSelected;
                }
                if (this.dvpSelected) {
                    q['dvp[]'] = this.dvpSelected;
                }
                if (this.dpSelected) {
                    q['dp[]'] = this.dpSelected;
                }
                if (this.ocpSelected) {
                    q['ocp[]'] = this.ocpSelected;
                }
                if (this.rezoningSelected) {
                    q['rez[]'] = this.rezoningSelected;
                }
                this.$router.push({ path: '/planning-updates', query: q});
                this.$router.go();
            },
            LandGo() {
                if (!this.role) {
                    return;
                }
                const vm = this;
                let q = {};
                if (this.parcelStatusLandSelected) {
                    q['status[]'] = this.parcelStatusLandSelected;
                }
                if (this.regTypeSelected) {
                    q['type[]'] = this.regTypeSelected;
                }
                if (this.LTSAPlanSearch) {
                    q['plansearch'] = this.LTSAPlanSearch;
                }
                if (this.LTSARegistrationSearch) {
                    q['regsearch'] = this.LTSARegistrationSearch;
                }
                if (this.ltsapid) {
                    q['pid'] = this.ltsapid;
                }
                if (this.landSalePriceMin) {
                    q['salepricemin'] = this.landSalePriceMin;
                }
                if (this.landSalePriceMax) {
                    q['salepricemax'] = this.landSalePriceMax;
                }
                if (this.landLotSizeMin) {
                    q['sizemin'] = this.landLotSizeMin;
                }
                if (this.landLotSizeMax) {
                    q['sizemax'] = this.landLotSizeMax;
                }
                if (this.registrationLandDate) {
                    q['regdate[]'] = new Array();
                }
                if (this.landCitiesSelected) {
                    q['city[]'] = this.landCitiesSelected;
                }
                this.registrationLandDate.forEach(function(value, i){
                    q['regdate[]'].push(vm.toTimestamp(value));
                });

                this.$router.push({ path: '/land-list', query: q});
                this.$router.go();
            },
            MLSReset() {

            },
            MLSGo() {
                if (!this.role) {
                    return;
                }
                const vm = this;
                let q = {};
                q['type[]'] = new Array();
                if (this.aptMLSIsActive) {
                    q['type[]'].push('2886');
                }
                if (this.townhomeMLSIsActive) {
                     q['type[]'].push('2870');
                }
                if (this.mixedUseAptMLSIsActive) {
                     q['type[]'].push('2884');
                }
                if (this.podiumMLSIsActive) {
                     q['type[]'].push('5910');
                }
                if (this.stackedTownhomeMLSIsActive) {
                     q['type[]'].push('3479');
                }
                if (this.commercialMLSIsActive) {
                     q['type[]'].push('2962');
                }
                if (this.industrialMLSIsActive) {
                     q['type[]'].push('3047');
                }
                if (this.rowhomeMLSIsActive) {
                     q['type[]'].push('6240');
                }
                if (this.statusMLSSelected) {
                    q['status[]'] = this.statusMLSSelected;
                }
                if (this.mlsCitiesSelected) {
                    q['city[]'] = this.mlsCitiesSelected;
                }
                if (this.gutMLSSelected) {
                    q['gut[]'] = this.gutMLSSelected;
                }
                if (this.mlsBedsMax) {
                    q['mlsbedsmax'] = this.mlsBedsMax;
                }
                if (this.mlsBedsMin) {
                    q['mlsbedsmin'] = this.mlsBedsMin;
                }
                if (this.mlsBathsMax) {
                    q['mlsbathsmax'] = this.mlsBathsMax;
                }
                if (this.mlsBathsMin) {
                    q['mlsbathsmin'] = this.mlsBathsMin;
                }
                if (this.mlsUnitMax) {
                    q['mlsunitmax'] = this.mlsUnitMax;
                }
                if (this.mlsUnitMin) {
                    q['mlsunitmin'] = this.mlsUnitMin;
                }
                if (this.listMLSDate) {
                    q['listmlsdate[]'] = new Array();
                }
                this.listMLSDate.forEach(function(value, i){
                    q['listmlsdate[]'].push(vm.toTimestamp(value));
                });
                if (this.soldMLSDate) {
                    q['soldmlsdate[]'] = new Array();
                }
                this.soldMLSDate.forEach(function(value, i){
                    q['soldmlsdate[]'].push(vm.toTimestamp(value));
                });
                if (this.mlsListPriceMin) {
                    q['mlslistpricemin'] = this.mlsListPriceMin;
                }
                if (this.mlsListPriceMax) {
                    q['mlslistpricemax'] = this.mlsListPriceMax;
                }
                if (this.mlsSoldPriceMin) {
                    q['mlssoldpricemin'] = this.mlsSoldPriceMin;
                }
                if (this.mlsSoldPriceMax) {
                    q['mlssoldpricemax'] = this.mlsSoldPriceMax;
                }
            
                this.$router.push({ path: '/mls-list', query: q});
                this.$router.go();
            },
        },
        mounted() {
            $.get({
                url: BACKEND_URL + 'api/get_role',
            }, (data, status) => {
                if (status === 'success') {
                    this.role = data.role;
                    this.loggedinEmail = data.email;
                    // this.role = '';
                    // this.loggedinEmail = 'admin@test.com';
                }
                if (this.role == 'admin' || this.role == 'plan_a') {
                    this.isLoggedin = true;
                }
            });
            $.get({
                url: BACKEND_URL + 'api/get_region',
            }, (data, status) => {
                if (status === 'success') {
                    this.cityoptions = data;
                }
            });
            $.get({
                url: BACKEND_URL + 'api/get_gut',
            }, (data, status) => {
                if (status === 'success') {
                    this.gutoptions = data;
                }
            });
            $.get({
                url: BACKEND_URL + 'api/get_status',
            }, (data, status) => {
                if (status === 'success') {
                    data.forEach((a) => {
                        if (a.tid.replace(' ', '') == this.status) {
                            $('#step3').append('<option selected value="'+ a.tid.replace(' ', '') + '">' + a.name + '</option>');
                            this.new_status = a.tid.replace(' ', '');
                            $('#stepStatus').append('<option selected value="'+ a.tid.replace(' ', '') + '">' + a.name + '</option>');
                            this.new_status = a.tid.replace(' ', '');
                        }
                        else {
                            $('#step3').append('<option value="'+ a.tid.replace(' ', '') + '">' + a.name + '</option>');
                            $('#stepStatus').append('<option value="'+ a.tid.replace(' ', '') + '">' + a.name + '</option>');
                        }
                    })
                    setTimeout(function(){
                        $('.better-select').niceSelect();
                    });
                }
            });
        }
    }
</script>
<style scoped>
    .logo {
        margin-bottom: 50px;
    }
    .logo img {
        max-width: 300px;
    }
    .container {
        max-width: none;
        padding: 0;
    }
    h2 {
        font-family: Poppins;
        font-weight: 700;
        font-size: 3rem;
    }
    p.no-secret-content {
        font-family: Poppins;
        font-size: 1.9rem;
        margin-top: 10px;
        font-weight: 300;
        color: #000000;
        line-height: 45px;
    }
    p b {
        font-weight: 600;
    }
    .no-secret {
        max-width: 700px;
    }
    .btn {
        color: #ffffff;
        background: #13293E;
        padding: 20px;
        border-radius: 45px;
        width: 300px;
        font-family: 'Poppins';
        font-weight: 500;
        font-size: 1.8rem;
        cursor: pointer;
        border: 3px solid #fff;
    }
    .no-secret-section {
        padding: 50px;
    }
    .premium-section {
        padding: 100px 50px;
        background: #13293E;
        color: #ffffff;
    }
    .premium-section h2 {
        margin-top: 50px;
        margin-bottom: 50px;
    }
    .premium-icon {
        width: 100px;
        height: 100px;
        margin-right: 20px;
    }
    .premium-wrapper {
        max-width: 1000px;
        display: inline-block;
        width: 100%;
    }
    .premium-block {
        text-align: left;
        margin-bottom: 40px;
    }
    .premium-block .row {
        margin-bottom: 20px;
    }
    .premium-content {
        font-size: 1.3rem;
        font-weight: 300;
        color: #ffffff;
        max-width: 250px;
    }
    .premium-wrapper h3 {
        font-family: Poppins;
        font-size: 1.5rem;
        font-weight: 600;
        margin-bottom: 10px;
    }
    .growth-wrapper {
        max-width: 700px;
    }
    p.growth-content {
        font-family: Poppins;
        font-size: 1.9rem;
        margin-top: 10px;
        font-weight: 300;
        color: #000000;
        line-height: 45px;
    }
    .business-growth {
        margin: 150px 0;
        overflow: visible;
    }
    .growth-img {
        width: 500px;
        margin-top: 80px;
        border-radius: 30px;
    }
    .accelerate-block {
        font-family: Poppins;
        font-weight: 600;
        font-size: 1.3rem;
        width: 150px;
        background: #1D184C;
        color: #fff;
        padding: 20px 0;
        position: absolute;
    }
    .accelerate-block.access {
        left: 50%;
        margin-left: 200px;
        top: 200px;
    }
    .accelerate-block.know {
        left: 50%;
        top: 450px;
        margin-left: -200px;
    }
    .accelerate-block.view {
        left: 50%;
        margin-left: -350px;
    }
    .accelerate-block.depth {
        left: 50%;
        margin-left: 200px;
    }
    .accelerate-block.listing {
        left: 50%;
        top: 350px;
        margin-left: -350px;
    }
    .accelerate-block.units {
        left: 50%;
        bottom: 0;
        margin-bottom: -50px;
        z-index: 9;
        margin-left: 200px;
    }
    .color-pink {
        color: #F15C6C !important;
    }
    .demo-review {
        background: #13293E;
        color: #ffffff;
        padding: 100px 0;
    }
    .demo-review h2 {
        margin-bottom: 50px;
    }
    .package {
        padding: 100px 0;
    }
    .package-wrapper {
        max-width: 1200px;
        margin: auto;
    }
    .package-block {
        background: #13293E;
        width: 400px;
        margin: auto;
        border-radius: 30px;
        color: #ffffff;
        padding: 30px;
        font-family: 'Poppins';
        height: 180px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
    }
    .package-block h3 {
        font-weight: 700;
        font-size: 2.3rem;
        width: 100%;
    }
    .package-block h4 {
        text-decoration: line-through;
        font-size: 1.3rem;
        font-weight: 500;
        width: 100%;
    }
    .package-content {
        text-align: left;
        width: 329px;
        display: block;
        margin: 50px auto;
        font-size: 1.3rem;
        font-family: Poppins;
        font-weight: 400;
    }
    .package-content .package-row {
        margin-bottom: 10px;
    }
    .package-content .package-row span {
        display: inline-block;
        margin-left: 35px;
        width: calc(100% - 30px);
    }
    .pkg-img {
        width: 30px;
        position: absolute;
    }
    .demo-wrapper {
        max-width: 1200px;
        margin: auto;
        position: relative;
    }
    .type-filter-button-sm {
        background: #FFFFFF;
        border: 3px solid #ffffff;
        box-sizing: border-box;
        border-radius: 10px;
        font-size: 14px;
        line-height: 15px;
        text-align: center;
        display: inline-block;
        cursor: pointer;
        position: relative;
        width: 100%;
        margin: 10px 10px;
        color: #ffffff;
        background: #13293E;
        font-family: 'Poppins';
        font-weight: 500;
        padding: 15px 25px;
    }
    .type-filter-button-sm.selected {
        color: #13293E;
        background-color: #fff;
    }
    .filter-label {
        font-weight: 500;
        font-size: 1.5rem;
        color: #ffffff;
        font-family: Poppins;
        text-align: left;
        margin-left: 10px;
        text-transform: capitalize;
    }
    .demo-input-normal {
        background: transparent;
        border: 3px solid #fff;
        border-radius: 10px;
        font-size: 1.2rem;
        color: #ffffff;
        padding: 10px 15px 10px 50px;
        width: 100%;
        margin: auto 10px;
        background-repeat: no-repeat;
        background-position: 12px;
    }
    .demo-input {
        background: transparent;
        border: 3px solid #fff;
        border-radius: 10px;
        font-size: 1.2rem;
        color: #ffffff;
        padding: 10px 15px 10px 50px;
        width: 100%;
        margin: auto 10px;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjNjY2IiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCI+PGNpcmNsZSBjeD0iMTEiIGN5PSIxMSIgcj0iOCIvPjxwYXRoIGQ9Ik0yMSAyMWwtNC00Ii8+PC9zdmc+");
        background-repeat: no-repeat;
        background-position: 12px;
    }
    .report-submit {
        text-align: center;
        width: 100%;
    }
    .filter-update {
        margin-top: 30px;
        background: #13293E;
        border: 3px solid #fff;
        border-radius: 30px;
        padding: 20px 50px;
        font-size: 1.2rem;
        display: inline-block;
        float: none;
    }
    .filter-update.hollow:hover {
        background: #fff;
        color: #13293E;
        border: 3px solid #13293E;
    }
    .filter-reset {
        margin-top: 30px;
        background: #13293E;
        border: 3px solid #fff;
        border-radius: 30px;
        padding: 20px 50px;
        font-size: 1.2rem;
        display: inline-block;
        float: none;
        color: #fff;
        margin-right: 100px;
    }
    .discover {
        position: absolute;
        top: 500px;
        width: 100%;
    }
    .discover h2 {
        color: #fff;
        font-family: Poppins;
        font-weight: 700;
        margin-top: 30px;
        margin-bottom: 30px;
    }
    .discover .btn {
        background: transparent;
        border: 3px solid #fff;
    }
    .btn.hollow:hover {
        background: #fff;
        color: #13293E;
        border: 3px solid #13293E;
    }
    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
    .listing-map {
        background: #fff;
        width: 60px;
        height: 60px;
        position: absolute;
        right: -70px;
        border-radius: 40px;
        margin-top: -10px;
    }
    .map-icon {
        width: 50px;
    }
    .package-title {
        margin-top: 50px;
        margin-bottom: 50px;
    }
    @media (max-width: 991px) {
        .row {
            width: 100%;
            margin-left: 0;
            margin-right: 0;
        }
        .accelerate-block.depth {
            right: 10px;
            left: initial;
            margin-left: 0;
        }
        .accelerate-block.units {
            right: 10px;
            left: initial;
            margin-left: 0;
        }
        .accelerate-block.listing {
            left: 40px;
            margin-left: 0;
            top: initial;
            bottom: 0;
            margin-bottom: -50px;
        }
        .growth-img {
            width: calc(100% - 100px);
        }
        .package-block {
            margin-top: 50px;
            width: auto;
            margin-left: 20px;
            margin-right: 20px;
        }
        .business-growth {
            padding-left: 20px;
            padding-right: 20px;
        }
        .business-block-text {
            order: 2;
            margin-top: 100px;
        }
        .business-block-img {
            order: 1;
        }
        .discover {
            position: absolute;
            top: 250px;
            width: 100%;
        }
        .discover h2 {
            font-size: 1rem;
        }
        .discover .btn {
            font-size: 1rem;
            width: 150px;
            padding: 8px;
        }
        .discover-icon {
            width: 40px;
        }
        h2 {
            font-size: 2rem;
        }
        p.no-secret-content {
            font-size: 1rem;
            line-height: 30px;
        }
        p.growth-content {
            font-size: 1.1rem;
            line-height: 30px;
        }
        .btn {
            padding: 15px;
            width: 250px;
            font-size: 1.3rem;
        }
        .premium-icon {
            width: 75px;
            height: 75px;
        }
        .premium-wrapper h3 {
            font-size: 1.3rem;
        }
        .package-title {
            margin-top: 0px;
        }
        .demo-wrapper {
            padding: 0 30px;
        }
        .type-filter-button-sm {
            font-size: 0.8rem;
            padding: 10px;
        }
        .premium-block .row {
            justify-content: center;
        }
    }
    @media (max-width: 470px) {
        .premium-section {
            padding: 100px 10px;
        }
        .premium-icon {
            width: 55px;
            height: 55px;
        }
        .premium-wrapper h3 {
            font-size: 1.1rem;
            margin-bottom: 5px;
        }
        .premium-content {
            font-size: 1.1rem;
        }
    }
    @media (max-width: 370px) {
        .package-block h3 {
            font-size: 1.8rem;
        }
        .package-block h4 {
            font-size: 1rem;
        }
    }
</style>
<style>
    .nav-tabs {
        border-bottom: 3px solid #fff !important;
    }
    .autocomplete-input {
        background: transparent;
        border: 3px solid #fff;
        border-radius: 10px;
        font-size: 1.2rem;
        color: #ffffff;
        padding: 10px 15px 10px 50px;
        width: 100%;
        margin: auto 10px;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjNjY2IiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCI+PGNpcmNsZSBjeD0iMTEiIGN5PSIxMSIgcj0iOCIvPjxwYXRoIGQ9Ik0yMSAyMWwtNC00Ii8+PC9zdmc+");
        background-repeat: no-repeat;
        background-position: 12px;
    }
    .autocomplete-input:focus {
        background: transparent;
        border: 3px solid #fff;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjNjY2IiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCI+PGNpcmNsZSBjeD0iMTEiIGN5PSIxMSIgcj0iOCIvPjxwYXRoIGQ9Ik0yMSAyMWwtNC00Ii8+PC9zdmc+");
        background-repeat: no-repeat;
        background-position: 12px;
    }
    .filter-checkbox {
        margin: auto 10px;
    }
    .filter-checkbox .vue-treeselect {
        background: transparent;
        border: 3px solid #fff;
        border-radius: 10px;
        font-size: 1.2rem;
        color: #ffffff;
        width: auto;
        padding: 5px;
    }
    .filter-checkbox .vue-treeselect .vue-treeselect__menu-container {
        color: #000;
    }
    .filter-checkbox .vue-treeselect .vue-treeselect__control {
        background: transparent;
        border: none;
    }
    .filter-checkbox .vue-treeselect .vue-treeselect__control {
        width: 100% !important;
    }
    .filter-select {
        margin: 10px;
    }
    .filter-select .nice-select.better-select {
        background: transparent;
        border: 3px solid #fff;
        border-radius: 10px;
        font-size: 1.2rem;
        color: #ffffff;
        padding: 8px 5px;
        width: 100%;
        height: 53px;
    }
    .filter-select .nice-select.better-select .list{
        background: #13293E;
        border: 3px solid #fff;
        padding-top: 0;
    }
    .filter-select .nice-select.better-select .multiple-options {
        font-size: 1.2rem;
        line-height: 30px;
        padding-left: 7px;
    }
    .filter-select .nice-select.better-select .option:hover, .filter-select .nice-select.better-select .option.focus, .filter-select .nice-select.better-select .option.selected.focus {
        background-color: #f6f6f6;
        color: #000;
    }
    .filter-checkbox .vue-treeselect__single-value {
        color: #fff;
    }
    .demo-wrapper .tabs ul li {
        font-size: 1.2rem;
        font-family: 'Poppins';
        text-transform: uppercase;
        font-weight: 500;
        width: 300px;
        padding-top: 5px;
        background: #13293E;
    }
    .demo-wrapper .nav-tabs .nav-link.active, .demo-wrapper .nav-tabs .nav-item.show .nav-link {
        background: #13293E;
        color: #fff;
        padding-top: 10px;
        border: 3px solid #fff;
        border-bottom: 3px solid #13293E;
        border-radius: 10px;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        margin-bottom: -3px;
    }
    .demo-wrapper .nav-tabs .nav-link:hover {
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 3px solid transparent;
    }
    .demo-wrapper .nav-tabs .nav-link.active:hover, .demo-wrapper .nav-tabs .nav-link:focus {
        border: 3px solid #fff;
        border-radius: 20px;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        border-bottom: 3px solid #13293E;
    }
    .demo-wrapper .tabs ul li a {
        color: #fff;
    }
    .demo-date input {
        background: transparent;
        border: 3px solid #fff;
        border-radius: 10px;
        font-size: 1.2rem;
        color: #ffffff;
        padding: 10px 10px;
        height: 50px;
        margin: 10px 0;
        width: 100%;
    }
    .demo-date.mx-datepicker.mx-datepicker-range {
        width: 100%;
        margin: 0 10px;
    }
    .filter-select .current {
        vertical-align: middle;
        margin-top: -20px;
        display: inline-block;
        margin-left: 20px;
    }
    .nav-tabs .nav-link {
        margin-bottom: -3px;
    }
    @media (max-width: 991px) {
        .demo-wrapper .tabs ul li {
            font-size: 1rem;
            width: 30%;
            max-width: 150px;
        }
        .demo-wrapper .nav-tabs .nav-link.active, .demo-wrapper .nav-tabs .nav-item.show .nav-link {
            border-radius: 7px;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
        }
        .demo-wrapper .col-lg-6 {
            padding-left: 0;
            padding-right: 0;
        }
        .demo-wrapper .col-sm-12 {
            padding-left: 0;
            padding-right: 0;
        }
        .demo-wrapper .col-xs-6 {
            padding-left: 0;
            padding-right: 0;
            margin-left: -7px;
        }
        .demo-wrapper .col-xs-6:nth-child(1) {
            padding-right: 15px;
        }
        .demo-wrapper .col-xs-6:nth-child(2) {
            padding-left: 15px;
        }
    }
</style>