<template>
    <div class="container">
        <div class="row">
            <div class="col-md-4 col-md-offset-4">
                <h1 style="font-size: 2rem" class="mb-5">Contact Us</h1>
                <div v-if="statusMessage" class="alert alert-success text-left">{{statusMessage}}</div>
                <form>
                    <div class="form-group">
                        <textarea class="form-control" id="message" v-model="message" rows="5"></textarea>
                    </div>
                    <button type="submit" class="btn btn-primary">Submit</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import {BACKEND_URL} from '../variables.js';
    export default {
        components: { 
        },
        data() {
            return {
                message: '',
                statusMessage: '',
            }
        },
        mounted: function() {
        },
        methods: {
            checkForm: function (e) {
                if (!this.errors.length) {
                    $.post(BACKEND_URL + 'api/message', {
                        message: this.message
                    }, (data, status) => {
                        if (data.status) {
                            this.statusMessage = 'Thanks for your feedback!';
                            setTimeout(function() {
                                this.$router.push({ path: '/'});
                                this.$router.go();
                            }, 2000);
                        }
                    });
                }
                e.preventDefault();
            }
        },
    }
</script>